import SvgIcon, { SvgIconPropTypes } from './svg-icon';

function ChevronLeft(props) {
  return (
    <SvgIcon {...props}>
      <path d='M0 0h24v24H0z' fill='none' />
      <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' />
    </SvgIcon>
  );
}

ChevronLeft.propTypes = SvgIconPropTypes;

export default ChevronLeft;
