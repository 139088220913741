import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './progress-bar.css';

const MIN = 0;
const MAX = 100;

function ProgressBar({ classes, value }) {
  return (
    <div className={classNames(styles.track, classes.track)}>
      <div
        role='progressbar'
        aria-valuemin={MIN}
        aria-valuemax={MAX}
        aria-valuenow={value}
        className={classNames(styles.bar, classes.bar)}
        style={{ width: `${Math.min(value, MAX)}%` }}
      />
    </div>
  );
}

export default ProgressBar;

ProgressBar.defaultProps = {
  classes: {},
};

ProgressBar.propTypes = {
  classes: PropTypes.shape({
    track: PropTypes.string,
    bar: PropTypes.string,
  }),

  value: (props, propName, componentName) => {
    const value = props[propName];

    if (typeof value !== 'number' || value < MIN || value > MAX) {
      return new Error(
        `Invalid value ${value} for ${propName} in ${componentName}. Value must be between ${MIN} and ${MAX}.`
      );
    }

    return null;
  },
};
