import classNames from 'classnames';

import Breakpoints from '../breakpoints.css';
import Palette from '../palette.css';
import Typography from '../typography.css';

function GlobalStylesProvider({ children }) {
  return (
    <>
      <div
        className={classNames(Breakpoints.inject, Palette.inject, Typography.inject)}
      />
      {children}
    </>
  );
}

export default GlobalStylesProvider;
