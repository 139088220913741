import { useQuery } from 'react-query';

import { fetchWrapper } from '@pumpkincare/portal-shared';

import usePlans from './use-plans';

function usePlanLegal(vetId) {
  return useQuery(
    ['planLegal', vetId],

    async () => fetchWrapper(`/vets/${vetId}/legal`),

    {
      enabled: !!vetId,
      placeholderData: { plans: [] },
      staleTime: 1000 * 60 * 60, // 1 hour in milliseconds
    }
  );
}

const ORDER_TITLE = [
  'IMPORTANT SAFETY INFORMATION',
  'PREVENTIVE ESSENTIALS PACKAGE SAVING INFORMATION',
];

export function formatPlanLegal(planLegals, allPlans) {
  /*
  on this page, we only want safety / saving information, not prescription food
  data: {
    plans: [
      {
        legal_info: [
          { title: ..., content: ... },
          ...
        ]
      },
      { legal_info: [ ... ] },
      ...
    ]
  }
 */
  const relevantLegal = [
    ...[].concat(
      ...planLegals.data.plans.map(
        plan => plan.legal_info.map(legal => ({ ...legal, plan_id: plan.plan_id }))
        // target: allPlans.find(allPlan => allPlan.plan_id === plan.id)?.target,
      )
    ),
  ].filter(legal => ORDER_TITLE.some(title => legal.title.match(title)));

  /*
    plans are gonna have some overlap (e.g. both cats will have same safety & savings copy)
    - get unique legal
    - use plan to get target and isDog
    - separate out by sections
    - sort each section by dog first and flatten array
    -- two tiered sorting would sometimes put cat first due to the small size of uniqueLegal
   */
  const uniqueLegal = [
    ...new Map(relevantLegal.map(legal => [legal.content, legal])).values(),
  ];
  const targetedLegal = uniqueLegal.map(legal => ({
    ...legal,
    isDog:
      allPlans.find(allPlan => allPlan.id === legal.plan_id).target === 'dog'
        ? 1
        : 0,
  }));
  const sectionedLegal = ORDER_TITLE.reduce((result, section, index) => {
    result[index] = targetedLegal.filter(legal => legal.title.match(section));
    return result;
  }, []);

  const sortedLegal = sectionedLegal
    .map(section => section.sort((a, b) => b.isDog - a.isDog))
    .flat();

  return {
    ...planLegals,
    data: sortedLegal,
  };
}

export default function useFormattedPlanLegal(vetId) {
  const planLegals = usePlanLegal(vetId);
  const { data: allPlans } = usePlans(vetId);

  return formatPlanLegal(planLegals, allPlans);
}
