import { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Body2 from '../../typography/body-2';

import ButtonStyles from '../../button.module.css';
import styles from './error-boundary.css';

import alertTriangle from '../../images/alert-triangle.svg';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.props.onError(error, errorInfo);
  }

  render() {
    const { itemRenderer } = this.props;

    if (this.state.hasError) {
      return (
        itemRenderer || (
          <div className={styles.errorBoundary}>
            <img src={alertTriangle} />

            <h4 className={styles.h4}>
              <span className={styles.blue}>
                Ruh Roh! Looks like something went wrong
              </span>
            </h4>

            <Body2>
              We are working to fix this error. You may want to refresh the page or
              try again later.
            </Body2>

            <button
              className={classNames(ButtonStyles.primary, styles.button)}
              onClick={this.props.onClick}
            >
              Refresh Page
            </button>
          </div>
        )
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  itemRenderer: PropTypes.element,
  onError: PropTypes.func,
  onClick: PropTypes.func,
};

ErrorBoundary.defaultProps = {
  onError: () => {},
  onClick: () => global.location.reload(),
};

export default ErrorBoundary;
