import { Children, cloneElement } from 'react';
import classNames from 'classnames';

import { useToggleState } from '@pumpkincare/portal-shared';

import Button from '../button';

import styles from './accordion.css';

import chevron from './chevron.svg';

export default function Accordion({
  classes = {},
  children,
  closedLabel,
  openLabel,
  staticContent,
  defaultValue = false,
  isFixedHeader = true,
  actionPosition = 'header',
}) {
  const [isExpanded, toggleIsExpanded] = useToggleState(defaultValue);

  function actionButton() {
    return (
      <Button onClick={toggleIsExpanded} className={styles.button}>
        {isExpanded ? openLabel : closedLabel}
        <img
          src={chevron}
          alt=''
          className={classNames(styles.chevron, {
            [styles.openChevron]: isExpanded,
          })}
        />
      </Button>
    );
  }

  return (
    <div className={classNames(styles.container, classes.container)}>
      <div className={classNames(styles.header, classes.header)}>
        {isFixedHeader || !isExpanded ? (
          <div className={styles.staticContent}>{staticContent}</div>
        ) : null}
        {actionPosition === 'header' ? actionButton() : null}
      </div>
      {isExpanded ? (
        <div
          className={classNames(styles.expandedContainer, classes.content)}
          onClick={() => toggleIsExpanded(false)}
        >
          {Children.map(children, (child, index) =>
            cloneElement(child, {
              key: `accordion-${index}`,
              className: classNames(styles.row, classes.row),
            })
          )}
        </div>
      ) : null}
      {actionPosition === 'footer' ? actionButton() : null}
    </div>
  );
}
