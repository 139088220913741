import {
  Children,
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import { useDisableScroll, useKeyboard } from '@pumpkincare/portal-shared';

import Button from '../button';
import TextField from '../text-field';

import styles from './search-field.css';

const TABLET_UP = '768';

/**
 * @deprecated - TO BE REPLACED WITH TEXT-FIELD / SELECT
 */
export default function SearchField({
  classes = {},
  children,
  onChange,
  onSearch = () => {},
  isLoading,
  isLabelHidden = true,
  iconAdornment: { hasIcon = true, icon: propsIcon } = {},
  dropdownOptions: {
    isAutocomplete = true,
    isDropdown = false,
    isIdle,
    isDefaultOpen,
    stickyFooter,
    startAdornment,
  } = {},
  ...restInputProps
}) {
  const { value, error = { isErrorHidden: true } } = restInputProps;

  const ref = useRef(null);
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [selectedIndex, setIndex] = useState(-1);
  const isCancelVisible = !isDropdown && value;
  const { disableScroll, enableScroll } = useDisableScroll({
    shouldDisableOnLoad: false,
  });

  const resetRef = useRef('');

  const search = useCallback(debounce(onSearch, 300), []);

  useEffect(() => {
    const handleClose = () => setIsFocused(false);
    window.addEventListener('click', handleClose);

    return () => {
      window.removeEventListener('click', handleClose);
    };
  }, []);

  function handleSettingFocus(val) {
    if (typeof val === 'function' || val !== isFocused) {
      setIsFocused(val);

      if (isDropdown) {
        const isImpactingOnlyKeys = window.innerWidth >= TABLET_UP;

        if (!isFocused) {
          disableScroll(isImpactingOnlyKeys);
        } else {
          enableScroll(isImpactingOnlyKeys);
        }
      }
    }
  }

  function handleFocus() {
    const { top, bottom } = ref.current.getBoundingClientRect();
    const modifier = window.innerWidth >= TABLET_UP && isDropdown ? 270 : 0;
    const dropdownDistanceToBottomOfWindow =
      window.innerHeight + window.pageYOffset - (bottom + modifier);

    if (!isDropdown) {
      window.scrollTo({ top });
    }

    if (isDropdown && dropdownDistanceToBottomOfWindow < 0) {
      window.scrollTo({ top: -1 * dropdownDistanceToBottomOfWindow });
    }

    if (isDropdown) {
      handleSettingFocus(true);
    }
  }

  function handleChange(e) {
    onChange(e);
    search(e);
    if (!isDropdown) window.scrollTo(0, 0);
  }

  function handleCancelClick() {
    handleChange({ target: { value: resetRef.current } });
    handleSettingFocus(false);
  }

  const icon = propsIcon || (isDropdown && isFocused ? 'close' : 'search');

  function handleIconClick() {
    if (icon === 'close') {
      handleCancelClick();
    }

    if (icon === 'select') {
      handleSettingFocus(val => !val);
    }
  }

  useKeyboard(
    {
      Escape: () => {
        handleChange({ target: { value: resetRef.current } });
        handleSettingFocus(false);
        inputRef.current.blur();
      },
      ArrowDown: () => {
        if (
          document.activeElement.closest('div')?.children[0] === inputRef.current
        ) {
          setIndex(val => val + 1);
        }
      },
      ArrowUp: () => {
        if (
          document.activeElement.closest('div')?.children[0] === inputRef.current
        ) {
          setIndex(val => val - 1);
        }
      },
    },
    []
  );

  useKeyboard(
    {
      Enter: () => {
        if (document.activeElement === inputRef.current && selectedIndex > -1) {
          const child = children.length > 1 ? children[selectedIndex] : children;

          child.props.onClick();
          handleSettingFocus(false);
          inputRef.current.blur();
          resetRef.current = child.props.value;
        }
      },
    },
    [selectedIndex]
  );

  return (
    <div
      className={classNames(styles.container, classes.container, {
        [styles.focusedContainer]: isFocused,
        [styles.childrenContainer]: isDropdown && !isFocused,
        [classes.focusedContainer]: isFocused,
      })}
      onClick={e => e.stopPropagation()}
    >
      <div
        className={classNames(styles.text, classes.text, {
          [styles.childrenInput]: isDropdown,
        })}
      >
        <TextField
          {...restInputProps}
          propRef={ref}
          inputRef={inputRef}
          onFocus={handleFocus}
          isFocused={isFocused}
          setIsFocused={handleSettingFocus}
          canType={isAutocomplete}
          classes={{
            ...classes,
            container: classNames(styles.container, classes.container),
            inputContainer: classNames(
              styles.inputContainer,
              classes.inputContainer
            ),
          }}
          onChange={handleChange}
          endAdornment={
            hasIcon
              ? {
                  icon,
                  isLoading,
                  onIconClick: handleIconClick,
                  iconClasses: classNames({
                    [styles.activeSelect]: icon === 'select' && isFocused,
                  }),
                }
              : {}
          }
          isLabelHidden={isLabelHidden}
          error={error}
        />

        {isCancelVisible ? (
          <Button
            className={classNames(styles.cancel, classes.cancel, {
              [styles.hiddenCancel]: !isFocused && isDropdown,
            })}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        ) : null}

        <hr />
        {isFocused && isDropdown && !isIdle ? (
          <div
            className={classNames(styles.children, classes.children, {
              [styles.desktopShow]: isDefaultOpen || (value && !isLoading),
            })}
          >
            <>
              {startAdornment}
              {Children.map(children, (child, idx) =>
                cloneElement(child, {
                  onMouseDown: e => {
                    child.props.onClick?.();
                    handleSettingFocus(false);
                    resetRef.current = e.target.value;
                  },
                  onMouseOver: () => setIndex(idx),
                  className: classNames(styles.child, child.props.className, {
                    [styles.active]: selectedIndex === idx,
                  }),
                })
              )}
              <div className={styles.placeholder}>{stickyFooter}</div>
            </>
            <div className={styles.sticky}>{stickyFooter}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
