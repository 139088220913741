import { useCallback, useState } from 'react';

function useToggleState(initialValue = false) {
  const [value, setValue] = useState(initialValue);

  const toggle = useCallback(nextValue => {
    if (typeof nextValue === 'boolean') {
      setValue(!!nextValue);
      return;
    }
    setValue(curValue => !curValue);
  }, []);

  return [value, toggle];
}

export default useToggleState;
