import SvgIcon, { SvgIconPropTypes } from './svg-icon';

function Expand(props) {
  return (
    <SvgIcon fill='none' {...props}>
      <path
        d='M18 2V7C18 7.55228 18.4477 8 19 8C19.5523 8 20 7.55228 20 7V1C20 0.447715 19.5523 0 19 0H13C12.4477 0 12 0.447715 12 1C12 1.55228 12.4477 2 13 2H18Z'
        fill='#373F51'
      />
      <path
        d='M2 13C2 12.4477 1.55228 12 1 12C0.447715 12 0 12.4477 0 13V19C0 19.5523 0.447715 20 1 20H7C7.55228 20 8 19.5523 8 19C8 18.4477 7.55228 18 7 18H2V13Z'
        fill='#373F51'
      />
      <path
        d='M18.2929 0.292893L11.2929 7.29289C10.9024 7.68342 10.9024 8.31658 11.2929 8.70711C11.6834 9.09763 12.3166 9.09763 12.7071 8.70711L19.7071 1.70711C20.0976 1.31658 20.0976 0.683418 19.7071 0.292893C19.3166 -0.0976311 18.6834 -0.0976311 18.2929 0.292893Z'
        fill='#373F51'
      />
      <path
        d='M1.70711 19.7071L8.70711 12.7071C9.09763 12.3166 9.09763 11.6834 8.70711 11.2929C8.31658 10.9024 7.68342 10.9024 7.29289 11.2929L0.292893 18.2929C-0.0976311 18.6834 -0.0976311 19.3166 0.292893 19.7071C0.683418 20.0976 1.31658 20.0976 1.70711 19.7071Z'
        fill='#373F51'
      />
      <path
        d='M2 13C2 12.4477 1.55228 12 1 12C0.447715 12 0 12.4477 0 13V19C0 19.5523 0.447715 20 1 20H7C7.55228 20 8 19.5523 8 19C8 18.4477 7.55228 18 7 18H2V13Z'
        fill='#373F51'
      />
      <path
        d='M18.2929 0.292893L11.2929 7.29289C10.9024 7.68342 10.9024 8.31658 11.2929 8.70711C11.6834 9.09763 12.3166 9.09763 12.7071 8.70711L19.7071 1.70711C20.0976 1.31658 20.0976 0.683418 19.7071 0.292893C19.3166 -0.0976311 18.6834 -0.0976311 18.2929 0.292893Z'
        fill='#373F51'
      />
      <path
        d='M1.70711 19.7071L8.70711 12.7071C9.09763 12.3166 9.09763 11.6834 8.70711 11.2929C8.31658 10.9024 7.68342 10.9024 7.29289 11.2929L0.292893 18.2929C-0.0976311 18.6834 -0.0976311 19.3166 0.292893 19.7071C0.683418 20.0976 1.31658 20.0976 1.70711 19.7071Z'
        fill='#373F51'
      />
      <path
        d='M18 2V7C18 7.55228 18.4477 8 19 8C19.5523 8 20 7.55228 20 7V1C20 0.447715 19.5523 0 19 0H13C12.4477 0 12 0.447715 12 1C12 1.55228 12.4477 2 13 2H18Z'
        fill='#373F51'
      />
    </SvgIcon>
  );
}

Expand.defaultProps = {
  size: 20,
};

Expand.propTypes = {
  ...SvgIconPropTypes,
};

export default Expand;
