import { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import LegalBody from '../legal-body';
import Processing from '../processing';

import styles from './text-field.css';

import close from './close-inverted.svg';
import search from './search.svg';
import select from './select-chevron.svg';

const icons = {
  select,
  search,
  close,
};

function TextField(props) {
  const {
    classes = {},
    label,
    onBlur,
    onFocus,
    isLabelHidden,
    isFocused: propsIsFocused,
    setIsFocused: propsSetIsFocused,
    variant,
    propRef,
    inputRef,
    error: { errorMessage = '', hasError, isErrorHidden = true },
    endAdornment: {
      icon,
      isLoading = false,
      endContent,
      endCustom,
      onIconClick = () => {},
      iconClasses,
    },
    startAdornment: { startContent, startCustom },
    legalMessage,
    canType,
    ...rest
  } = props;
  const { defaultValue, id, placeholder, readOnly, required, value } = rest;

  const outerRef = propRef || useRef(null);
  const innerRef = inputRef || useRef(null);
  const shouldShowPlaceholder = !!useMemo(
    () => placeholder && label !== placeholder,
    [label, placeholder]
  );
  const variants = useMemo(
    () => ({
      isPortal: variant === 'portal',
      isDefault: variant === 'default',
      isOutline: variant === 'outline',
    }),
    [variant]
  );

  const [isFocusedLocal, setIsFocusedLocal] = useState(false);
  const isFocused =
    typeof propsIsFocused === 'boolean' ? propsIsFocused : isFocusedLocal;

  function setIsFocused(input) {
    if (propsSetIsFocused) {
      propsSetIsFocused(input);
    } else {
      setIsFocusedLocal(input);
    }
  }

  function handleFocus(e) {
    onFocus(e);
    setIsFocused(true);
  }

  function handleBlur(e) {
    onBlur(e);
    setIsFocused(false);
  }

  const hasValue = !!value || defaultValue || !!innerRef.current?.value;
  const focusedStyle = { [styles.focused]: isFocused && !readOnly };

  const labelClassName = classNames(classes.label, {
    [styles.label]: variants.isDefault,
    [styles.outlineLabel]: variants.isOutline,
    [styles.portalLabel]: variants.isPortal,
    [styles.adornmentLabel]: startContent || startCustom,
    [styles.error]: errorMessage || hasError,
    [styles.placeholder]: !(isFocused || hasValue),
    [styles.hidden]: isLabelHidden && (hasValue || isFocused),
    [styles.readOnly]: readOnly,
    ...focusedStyle,
  });
  const inputClassName = classNames(styles.input, classes.input, {
    [styles.input]: variants.isDefault,
    [styles.outlineInput]: variants.isOutline,
    [styles.portalInput]: variants.isPortal,
    [styles.error]: errorMessage || hasError,
    [styles.readOnly]: readOnly,
    [styles.hasStartAdornment]: (variants.isOutline && startContent) || startCustom,
    [styles.showPlaceholder]: shouldShowPlaceholder && (isFocused || hasValue),
    ...focusedStyle,
  });
  const borderClassName = classNames(styles.border, {
    [styles.readOnly]: readOnly,
    ...focusedStyle,
  });

  function handleLabelClick() {
    onFocus();
    innerRef.current.focus();
  }

  return (
    <div
      className={classNames(styles.container, classes.container, {
        [styles.unfocused]: !isFocused,
      })}
    >
      <div
        className={classNames(styles.fieldContainer, classes.fieldContainer)}
        ref={outerRef}
      >
        <label htmlFor={id} className={labelClassName} onClick={handleLabelClick}>
          {label || placeholder}
          {required ? <span className={styles.asterisk}>*</span> : null}
        </label>

        <div
          className={classNames(classes.inputContainer, {
            [styles.inputContainer]: variants.isDefault,
            [styles.outlineInputContainer]: variants.isOutline,
            [styles.portalInputContainer]: variants.isPortal,
            [styles.filled]: hasValue,
            [styles.errorBorder]: errorMessage || hasError,
            ...focusedStyle,
          })}
        >
          {variants.isOutline && (startContent || startCustom) ? (
            <div
              className={classNames(styles.startAdornment, {
                [styles.startContent]: startContent,
              })}
            >
              {startContent || startCustom}
            </div>
          ) : null}

          <input
            {...rest}
            className={inputClassName}
            onBlur={handleBlur}
            onFocus={handleFocus}
            ref={innerRef}
            placeholder={placeholder || label}
            readOnly={readOnly || !canType}
          />

          {icon && !isLoading ? (
            <button
              type='button'
              className={classNames(
                styles.iconButton,
                styles[`icon-${icon}`],
                borderClassName,
                iconClasses,
                {
                  [styles.focusedButton]: isFocused,
                  [styles.error]: hasError || errorMessage,
                  [styles.filledButton]: hasValue,
                }
              )}
              onClick={onIconClick}
              aria-label={icon}
            >
              <img src={icons[icon]} alt='' />
            </button>
          ) : null}
          {isLoading ? (
            <div className={classNames(styles.spinner, borderClassName)}>
              {/* pumpkin-client uses a Spinner component with size prop */}
              <Processing classes={{ ring: styles.ring }} />
            </div>
          ) : null}

          {endContent || endCustom ? (
            <div
              className={classNames(styles.endAdornment, borderClassName, {
                [styles.error]: hasError || errorMessage,
                [styles.endContent]: endContent,
              })}
            >
              {endContent || endCustom}
            </div>
          ) : null}
        </div>

        {legalMessage}

        {!isErrorHidden || errorMessage ? (
          <LegalBody className={styles.errorMessage}>{errorMessage}</LegalBody>
        ) : null}
      </div>
    </div>
  );
}

TextField.defaultProps = {
  autoComplete: 'off',
  autoFocus: false,
  maxLength: '',
  canType: true,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onKeyUp: () => {},
  type: 'text',
  classes: {},
  isLabelHidden: false,
  variant: 'default',
  error: {},
  endAdornment: {},
  startAdornment: {},
};

TextField.propTypes = {
  classes: PropTypes.shape({
    label: PropTypes.string,
    input: PropTypes.string,
    container: PropTypes.string,
    fieldContainer: PropTypes.string,
    inputContainer: PropTypes.string,
  }),
  /** A readOnly proxy that doesn't come with native readOnly styling & ADA */
  canType: PropTypes.bool,
  /** A label that covers the placeholder and moves up above the element when focused / having a value */
  label: PropTypes.string,
  /** trigger functionality when clicking away from a focused element */
  onBlur: PropTypes.func,
  /** trigger functionality when focusing into an element */
  onFocus: PropTypes.func,
  /** hide the label */
  isLabelHidden: PropTypes.bool,
  /** preset variants if you dont want to play with too many props */
  variant: PropTypes.oneOf(['default', 'outline', 'portal']),
  /** if you want to pass in a ref object to refer to outside of the component */
  propRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  /** if you want to pass in a ref object to refer to outside of the component */
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  /** error handling - errorMessage if we want to see a specific error, hasError if you just want error styling, isErrorHidden to hide native errors */
  error: PropTypes.shape({
    errorMessage: PropTypes.node,
    hasError: PropTypes.bool,
    isErrorHidden: PropTypes.bool,
  }),
  /** for adding content inline at the end of the input */
  endAdornment: PropTypes.shape({
    icon: PropTypes.node,
    isLoading: PropTypes.bool,
    endContent: PropTypes.node,
    endCustom: PropTypes.node,
    onIconClick: PropTypes.func,
    iconClasses: PropTypes.string,
  }),
  /** for adding content inline at the beginning of the input */
  startAdornment: (props, propName, componentName) => {
    const value = props[propName];

    if (typeof value !== 'object' || (value.startCustom && value.startContent)) {
      return new Error(
        `Invalid value ${value} for ${propName} in ${componentName}. Value must be an object with either startCustom OR startContent`
      );
    }

    if (Object.keys(value).length && props.variant !== 'outline') {
      return new Error(
        `${propName} is currently only supported for variant='outline' in ${componentName}`
      );
    }

    return null;
  },
  /** for adding any additional language beneath the field, but above error messages */
  legalMessage: PropTypes.node,

  // spread via ...rest
  /** will enable browser behavior, useful for email / name fields */
  autoComplete: PropTypes.oneOf(['off', 'on']),
  /** react property for focusing elements */
  autoFocus: PropTypes.bool,
  /** native html property for specifying number of characters an input can have */
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** necessary for updating the value in a controlled input */
  onChange: PropTypes.func,
  /** similar to onChange, but useful for formatting values of the field after making the change (e.g. adding to a date field) */
  onKeyUp: PropTypes.func,
  /** native html property that references the element */
  name: PropTypes.string,
  /** property that enables cypress testing */
  'data-testid': PropTypes.string,
  /** necessary for semantic html */
  id: PropTypes.string,
  /** necessary for rendering value in a controlled input */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** useful for specifying the value in an uncontrolled input when the component loads */
  defaultValue: PropTypes.string,
  /** value that will show up explicitly in the input box */
  placeholder: PropTypes.string,
  /** native html property for enforcing completeness, but will also append a red asterisk */
  required: PropTypes.bool,
  /** native html property for preventing edits to the field */
  readOnly: PropTypes.bool,
  /** native html property on inputs that triggers extra behavior / special keyboards on mobile devices. While there are more [input types](https://www.w3schools.com/html/html_form_input_types.asp) available generally, only these 3 should be used with TextField */
  type: PropTypes.oneOf(['email', 'text', 'password']),
};

export default TextField;
