import { useQuery } from 'react-query';

import fetchWrapper from '../fetch-wrapper';

export function useBreeds(species = '', options = {}) {
  return useQuery(
    ['petBreeds', species],

    () =>
      fetchWrapper(
        `https://utils.api.pumpkin.care/v1/breeds/${species.toLowerCase()}s`,
        { isExternal: true }
      ),

    {
      enabled: !!species,
      placeholderData: [],
      staleTime: 1000 * 60 * 10,
      ...options,
    }
  );
}

export function useBreedReports(breedCode = '', options = {}) {
  return useQuery(
    ['petBreedReports', breedCode],

    () =>
      fetchWrapper(`https://utils.api.pumpkin.care/v1/breed-report/${breedCode}`, {
        isExternal: true,
      }),

    {
      enabled: !!breedCode,
      placeholderData: {},
      staleTime: 1000 * 60 * 10,
      ...options,
    }
  );
}
