// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popover_container_ee73f527 {\n    padding: 24px;\n    position: absolute;\n    background: white;\n    z-index: 998;\n    box-sizing: border-box;\n}\n\n.popover_backdrop_a9c163be {\n    position: absolute;\n    top: 72px;\n    left: 0;\n    width: 100vw;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: -1;\n}", "",{"version":3,"sources":["webpack://../../libs/portal-ui/src/popover/popover.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,YAAY;IACZ,oCAAoC;IACpC,WAAW;AACf","sourcesContent":[".container {\n    padding: 24px;\n    position: absolute;\n    background: white;\n    z-index: 998;\n    box-sizing: border-box;\n}\n\n.backdrop {\n    position: absolute;\n    top: 72px;\n    left: 0;\n    width: 100vw;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: -1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "popover_container_ee73f527",
	"backdrop": "popover_backdrop_a9c163be"
};
export default ___CSS_LOADER_EXPORT___;
