export function filterSpeciesPlan(plans, species) {
  return plans
    .filter(({ target }) => target === species)
    .sort((a, b) => a.min_age_months - b.min_age_months);
}

export function isPrevent4(plan) {
  return Number(plan.version_number) >= 4;
}

/**
 * Return base prevent version (e.g. 4.1 => 4, 3.0 => 3)
 * @param {object} plan - a single plan
 */
export function getBasePreventVersion(plan = {}) {
  return Math.floor(Number(plan.version_number));
}

export function hasPrevent4Plans(plans) {
  return plans.some(isPrevent4);
}
