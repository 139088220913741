// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a_link_07fc7817,\n.a_link_07fc7817:visited,\n.a_link_07fc7817:active {\n    text-decoration: none;\n    color: var(--bluesClues);\n}\n\n.a_link_07fc7817:hover {\n    text-decoration: none;\n}", "",{"version":3,"sources":["webpack://../../libs/portal-ui/src/a/a.css"],"names":[],"mappings":"AAAA;;;IAGI,qBAAqB;IACrB,wBAAwB;AAC5B;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".link,\n.link:visited,\n.link:active {\n    text-decoration: none;\n    color: var(--bluesClues);\n}\n\n.link:hover {\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "a_link_07fc7817"
};
export default ___CSS_LOADER_EXPORT___;
