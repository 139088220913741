import { useMutation, useQueryClient } from 'react-query';

import { POLICY_BASE_API } from '@pumpkincare/portal-config';
import { fetchWrapper } from '@pumpkincare/portal-shared';

import { QUOTE_QUERY } from './quotes-query';

export function useMutatePostQuote() {
  /**
   * Return the RQ method for creating a quote and other RQ data around request state
   * @param {boolean} isLoggedIn - is the user logged in
   * @param {string} tracking_id - segment tracking_id
   * @param {string} identity_id - identity_id in Pumpkin DB associated with tracking_id
   * @param {string} affiliate_code - code to set a quote with vet-channel affiliate for attribution
   */
  return useMutation(({ isLoggedIn, ...body }) =>
    fetchWrapper(`/quotes-v3`, {
      method: 'POST',
      baseApi: POLICY_BASE_API,
      isAuth: isLoggedIn,
      body,
    })
  );
}

export function useMutatePostQuotePet() {
  /**
   * Return the RQ method for updating a quote with a new pet and other RQ data around request state
   * @param {string} quoteId
   * @param {string} pet_name
   * @param {string} species - Dog v Cat
   */
  return useMutation(({ quoteId, ...body }) => {
    return fetchWrapper(`/quotes-v3/${quoteId}/pets`, {
      method: 'POST',
      baseApi: POLICY_BASE_API,
      body,
    });
  });
}

export function useMutatePatchQuotePet() {
  /**
   * Return the RQ method for updating a quote pet and other RQ data around request state
   * @param {string} quoteId
   * @param {string} pet - id, breed_code, breed_name, breed_type, pet_age, pet_name, species
   */
  return useMutation(({ quoteId, pet }) => {
    return fetchWrapper(`/quotes-v3/${quoteId}/pets/${pet.id}`, {
      method: 'PATCH',
      baseApi: POLICY_BASE_API,
      body: {
        pet_name: pet.petName,
        species: pet.petBreedSpecies,
        pet_gender: pet.petGender,
        pet_age: pet.petAge,
        breed_code: pet.petBreedCode,
        breed_name: pet.petBreedName,
        breed_type: pet.petBreedType,
      },
    });
  });
}

export function useMutatePatchQuoteVet() {
  /**
   * Return the RQ method for updating a quote vet attribution
   * @param {string} quoteId
   * @param {string} vetId
   */
  return useMutation(({ quoteId, vetId }) => {
    return fetchWrapper(`/quotes-v3/${quoteId}/vet`, {
      method: 'PATCH',
      baseApi: POLICY_BASE_API,
      body: {
        vet_id: vetId,
      },
    });
  });
}

/**
 * Returns RQ methods and the mutation for registering a quote. It will also invalidate the quote query
 * @param quoteId
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */

export function useMutateRegisterQuote(quoteId) {
  const queryClient = useQueryClient();

  /**
   * Return the RQ method for registering a quote
   * @param {string} quotePetId
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} email
   * @param {string} zipcode
   * @param {string} trackingId
   * @param {string} discountId
   */
  return useMutation(
    ({
      quotePetId,
      firstName,
      lastName,
      email,
      zipcode,
      trackingId = undefined,
      discountId = undefined,
    }) =>
      fetchWrapper(`/quotes-v3/${quoteId}/register-quote`, {
        method: 'POST',
        baseApi: POLICY_BASE_API,
        body: {
          email,
          quote_pet_id: quotePetId,
          first_name: firstName,
          last_name: lastName,
          policy_zipcode: zipcode,
          tracking_id: trackingId,
          discount_id: discountId,
        },
      }),

    {
      // Always re fetch after error or success
      onSettled: () => queryClient.invalidateQueries([QUOTE_QUERY, quoteId]),
    }
  );
}
