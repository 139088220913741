import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '../../typography.module.css';
import styles from './checkbox.module.css';

function Checkbox({
  classes = {},
  isError = false,
  style = {},
  label = null,
  large,
  onChange,
  'data-testid': dataTestId,
  ...restInputProps
}) {
  return (
    <label
      className={classNames(styles.root, classes.root, {
        [styles.large]: large,
      })}
      style={style}
      onChange={onChange}
      data-testid={dataTestId}
    >
      <input
        className={styles.checkbox}
        type='checkbox'
        onChange={() => {}}
        data-is-error={isError}
        {...restInputProps}
      />
      <div className={styles.image} aria-hidden />
      <span
        className={classNames(styles.label, {
          [Typography.legalBody]: !large,
          [Typography.body2]: large,
        })}
      >
        {label}
      </span>
    </label>
  );
}

Checkbox.propTypes = {
  classes: PropTypes.object,
  isError: PropTypes.bool,
  label: PropTypes.node,
  onChange: PropTypes.func,
  style: PropTypes.object,
  large: PropTypes.bool,
  'data-testid': PropTypes.string,

  // props for restInputProps autocomplete
  name: PropTypes.string,
  checked: PropTypes.bool,
  'aria-checked': PropTypes.bool,
  value: PropTypes.any,
};

export default Checkbox;
