// https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings

// These options are needed to round to whole numbers if that's what you want.
// minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
// maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
const floatFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const integerFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export function formatCurrency(value, { useInteger } = {}) {
  const formatter = useInteger ? integerFormatter : floatFormatter;
  return formatter.format(Number(value));
}

export function formatCentsCurrency(value, { useInteger } = {}) {
  const formatter = useInteger ? integerFormatter : floatFormatter;
  return formatter.format(Number(value / 100));
}
