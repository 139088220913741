import SvgIcon, { SvgIconPropTypes } from './svg-icon';

function Document(props) {
  return (
    <SvgIcon fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.7071 1.29289C14.5196 1.10536 14.2652 1 14 1H6C4.34315 1 3 2.34315 3 4V20C3 21.6569 4.34315 23 6 23H18C19.6569 23 21 21.6569 21 20V8C21 7.73478 20.8946 7.48043 20.7071 7.29289L14.7071 1.29289ZM6 3H13.5858L19 8.41421V20C19 20.5523 18.5523 21 18 21H6C5.44772 21 5 20.5523 5 20V4C5 3.44772 5.44772 3 6 3Z'
        fill='#9B9FA8'
      />
      <path
        d='M15 2C15 1.44772 14.5523 1 14 1C13.4477 1 13 1.44772 13 2V8C13 8.55228 13.4477 9 14 9H20C20.5523 9 21 8.55228 21 8C21 7.44772 20.5523 7 20 7H15V2Z'
        fill='#9B9FA8'
      />
      <path
        d='M16 12H8C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14 8 14H16C16.5523 14 17 13.5523 17 13C17 12.4477 16.5523 12 16 12Z'
        fill='#9B9FA8'
      />
      <path
        d='M16 16H8C7.44772 16 7 16.4477 7 17C7 17.5523 7.44772 18 8 18H16C16.5523 18 17 17.5523 17 17C17 16.4477 16.5523 16 16 16Z'
        fill='#9B9FA8'
      />
      <path
        d='M10 8H8C7.44772 8 7 8.44772 7 9C7 9.55228 7.44772 10 8 10H10C10.5523 10 11 9.55228 11 9C11 8.44772 10.5523 8 10 8Z'
        fill='#9B9FA8'
      />
    </SvgIcon>
  );
}

Document.propTypes = {
  ...SvgIconPropTypes,
};

export default Document;
