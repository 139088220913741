import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './spinner.module.css';

function Spinner({ size = 40, thickness = 4, role = '', classes = {}, ...rest }) {
  return (
    <svg
      data-testid='spinner-svg'
      className={classNames(styles.root, classes.root)}
      width={size}
      height={size}
      role={role}
      viewBox={`0 0 50 50`}
      {...rest}
    >
      <circle
        data-testid='spinner-circle'
        className={classNames(styles.spinner, classes.spinner)}
        strokeWidth={thickness}
        fill='none'
        cx='50%'
        cy='50%'
        r='22'
      />
    </svg>
  );
}

Spinner.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    spinner: PropTypes.string,
  }),

  size: PropTypes.number,
  thickness: PropTypes.number,

  // spread via ...rest
  'data-testid': PropTypes.string,
};

export default Spinner;
