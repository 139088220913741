import classNames from 'classnames';

import styles from './body1.css';

function Body1({ children, className, style }) {
  return (
    <div className={classNames(styles.body1, className)} style={style}>
      {children}
    </div>
  );
}

export default Body1;
