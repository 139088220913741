// these values match breakpoints.css
const breakpoints = {
  values: {
    mobile: 768,
    desktop: 1280,
  },
};

const screenSizes = {
  isMobileOnly: {
    max: breakpoints.values.mobile,
  },
  isTabletUp: {
    min: breakpoints.values.mobile,
    max: breakpoints.values.desktop,
  },
  isDesktopUp: {
    min: breakpoints.values.desktop,
  },
};

/*
  matchScreenSizes will return an object similar to screenSizes where each key will point to whether or not the window screen is within the range specified
  mediaBoolean refers to isMobileOnly / isTabletUp / isDesktopUp
  size refers to min / max
 */
export function matchScreenSizes() {
  return Object.keys(screenSizes).reduce((iterator, mediaBoolean) => {
    const mediaQuery = Object.keys(screenSizes[mediaBoolean]).reduce(
      (query, size) => {
        const value = screenSizes[mediaBoolean][size];
        const newQuery = `(${size}-width: ${value}px)`;
        return query ? `${query} and ${newQuery}` : newQuery;
      },
      ''
    );

    return { ...iterator, [mediaBoolean]: window.matchMedia(mediaQuery).matches };
  }, {});
}
