import { useEffect, useRef } from 'react';
import classNames from 'classnames';

import styles from './popover.css';

function Popover({ className, style, children, onOutsideClick = false }) {
  const popoverWrapperRef = useRef(null);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (
        popoverWrapperRef.current &&
        !popoverWrapperRef.current.contains(event.target) &&
        onOutsideClick instanceof Function
      ) {
        onOutsideClick();
      }
    }

    if (onOutsideClick) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onOutsideClick, popoverWrapperRef]);

  return (
    <>
      <div
        ref={popoverWrapperRef}
        className={classNames(styles.container, className)}
        style={style}
      >
        {children}
      </div>
      <div className={styles.backdrop} style={style} />
    </>
  );
}

export default Popover;
