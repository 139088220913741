import { useQuery } from 'react-query';

import { fetchWrapper } from '@pumpkincare/portal-shared';

export const getVetState = vet => vet.state;
export const getVetDisplayName = vet => vet.vet_display_name;

export const VET_QUERY = 'vet';

export function getVetQueryFn(vetId) {
  return fetchWrapper(`/vets/${vetId}`);
}

export function useVet(vetId, options = {}) {
  return useQuery(
    [VET_QUERY, vetId],

    async () => fetchWrapper(`/vets/${vetId}`),

    {
      enabled: !!vetId,
      placeholderData: {},
      staleTime: 1000 * 60 * 60, // 1 hour in milliseconds
      ...options,
    }
  );
}
