import classNames from 'classnames';

import styles from './button.css';

function Button({
  className,
  children,
  type = 'button',
  disabled = false,
  styledDisabled,
  style = {},
  onClick = () => {},
  ...rest
}) {
  return (
    <button
      type={type}
      disabled={disabled}
      className={classNames(styles.button, className, {
        [styles.disabled]: styledDisabled,
      })}
      onClick={onClick}
      style={style}
      {...rest}
    >
      {children}
    </button>
  );
}

export default Button;
