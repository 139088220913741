import Store from 'store';

import { captureException } from './error-tracking';

export function setIsLoggedIn(key, value) {
  try {
    Store.set(key, value);
  } catch (e) {
    captureException(e);
  }
}

export function getIsLoggedIn(key) {
  try {
    return !!Store.get(key);
  } catch (e) {
    captureException(e);
  }
}
