import { useMemo } from 'react';
import classNames from 'classnames';

import { USER_AGENTS } from '@pumpkincare/portal-shared';

import Body1 from '../body1';
import Button from '../button';
import { determineBlocks, formatPaginationCopy } from './pagination-utils';

import styles from './pagination.css';

import arrowRight from './arrow-right.svg';

export default function Pagination({
  resultsPerPage = 10,
  totalResults,
  page,
  unit,
  onPageChange,
  maxNumBlocks = USER_AGENTS.desktop ? 9 : 7,
  classes = {},
}) {
  const buffer = (maxNumBlocks - 1) / 2;

  const finalPage = useMemo(
    () => Math.ceil(totalResults / resultsPerPage),
    [totalResults, resultsPerPage]
  );
  const isFirstPage = page === 1;
  const isFinalPage = page === finalPage;

  function handlePageChange(newPage) {
    window.scrollTo(0, 0);

    onPageChange(newPage);
  }

  function handleLeftClick() {
    if (!isFirstPage) {
      handlePageChange(page - 1);
    }
  }

  function handleRightClick() {
    if (!isFinalPage) {
      handlePageChange(page + 1);
    }
  }

  function handleItemClick(newPage, index) {
    if (newPage !== '...') {
      handlePageChange(newPage);
    } else {
      handlePageChange(index === 1 ? page - buffer : page + buffer);
    }
  }

  return (
    <div className={classNames(styles.container, classes.container)}>
      <div className={classNames(styles.buttons, classes.buttons)}>
        <Button
          onClick={handleLeftClick}
          className={classNames(styles.page, classes.page, {
            [styles.notAllowed]: isFirstPage,
          })}
        >
          <img src={arrowRight} alt='down one' className={styles.leftArrow} />
        </Button>

        {determineBlocks(page, finalPage, maxNumBlocks).map((block, index) => (
          <Button
            key={`${block}-${index}`}
            onClick={() => handleItemClick(block, index)}
            className={classNames(styles.page, classes.page, {
              [styles.selected]: block === page,
            })}
          >
            {block}
          </Button>
        ))}

        <Button
          onClick={handleRightClick}
          className={classNames(styles.page, classes.page, {
            [styles.notAllowed]: isFinalPage,
          })}
        >
          <img src={arrowRight} alt='up one' className={styles.arrow} />
        </Button>
      </div>

      <Body1 style={{ textAlign: 'center' }}>
        {formatPaginationCopy(page, resultsPerPage, totalResults, unit)}
      </Body1>
    </div>
  );
}
