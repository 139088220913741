import { useCallback, useEffect, useState } from 'react';

import { IDLE, LOADING } from '../../constants/action-statuses';

export default function useDelayedLoading(statuses, minTime) {
  const [hasReachedMinimumTime, setHasReachedMinimumTime] = useState(false);
  const setHasReachedMinimumTimeToTrue = useCallback(() => {
    setHasReachedMinimumTime(true);
  }, []);

  useEffect(() => {
    setTimeout(setHasReachedMinimumTimeToTrue, minTime);
  }, [setHasReachedMinimumTimeToTrue, minTime]);

  return (
    !hasReachedMinimumTime ||
    statuses.some(status =>
      typeof status === 'boolean' ? status : status === LOADING || status === IDLE
    )
  );
}
