import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { useGestures, useKeyboard } from '@pumpkincare/portal-shared';

import styles from './carousel.css';

import arrow from '../../../../public/assets/paw-portal/icons/arrow-right.svg';

function Carousel({
  children,
  title = null,
  classes = {},
  onChange = () => {},
  index: externalIndex = 0,
}) {
  const [index, setIndex] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setIndex(externalIndex);
  }, [externalIndex]);

  function handleChange(redirectTo) {
    setIndex(redirectTo);
    onChange(redirectTo);
  }

  function handleShift(direction) {
    // "right" is 1, "left" is 0
    const newIndex = direction ? index + 1 : index - 1;

    if (newIndex >= 0 && newIndex < children.length) {
      handleChange(newIndex);
    }
  }

  function handleRightShift() {
    handleShift(1);
  }

  function handleLeftShift() {
    handleShift(0);
  }

  useKeyboard(
    {
      ArrowLeft: handleLeftShift,
      ArrowRight: handleRightShift,
    },
    [index]
  );

  useGestures(ref, {
    onSwipeLeft: handleRightShift,
    onSwipeRight: handleLeftShift,
  });

  const indicatorClassname = classNames(styles.indicator, classes.indicator);

  const activeIndicatorClassname = classNames(
    styles.indicatorActive,
    classes.indicatorActive
  );

  return (
    <div className={classNames(styles.container, classes.container)} ref={ref}>
      {title}
      <button
        type='button'
        className={classNames(styles.arrowLeft, {
          [styles.arrowLeftDisabled]: index === 0,
        })}
        onClick={handleLeftShift}
        data-testid='left-arrow'
      >
        <img className={styles.arrowLeftImage} src={arrow} alt='Left Arrow Button' />
      </button>

      <div
        style={{
          width: `${children.length * 100}%`,
          left: `${index * -100}%`,
        }}
        className={styles.itemsContainer}
      >
        {children.map((child, index) => (
          <div className={styles.item} key={index}>
            {child}
          </div>
        ))}
      </div>

      <button
        type='button'
        className={classNames(styles.arrowRight, {
          [styles.arrowRightDisabled]: index === children.length - 1,
        })}
        onClick={handleRightShift}
        data-testid='right-arrow'
      >
        <img
          className={styles.arrowRightImage}
          src={arrow}
          alt='Right Arrow Button'
        />
      </button>

      <ul className={styles.indicators}>
        {children.map((item, key) => {
          const className = classNames(indicatorClassname, {
            [activeIndicatorClassname]: index === key,
          });

          return (
            <li key={key} className={className} onClick={() => handleChange(key)} />
          );
        })}
      </ul>
    </div>
  );
}

export default Carousel;
