import { formatDateFromTextInput } from '@pumpkincare/portal-shared';

const monthMap = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function getNumberOfDays(year, month) {
  return 40 - new Date(year, month, 40).getDate();
}

function getDayDetails(args) {
  const dateArg = args.index - args.firstDay;
  const day = args.index % 7;
  let prevMonth = args.month - 1;
  let prevYear = args.year;
  if (prevMonth < 0) {
    prevMonth = 11;
    prevYear -= 1;
  }
  const prevMonthNumberOfDays = getNumberOfDays(prevYear, prevMonth);
  const date =
    (dateArg < 0 ? prevMonthNumberOfDays + dateArg : dateArg % args.numberOfDays) +
    1;
  const timestamp = new Date(args.year, args.month, date).getTime();
  let month = 0;
  if (dateArg < 0) {
    month = -1;
  } else if (dateArg >= args.numberOfDays) {
    month = 1;
  }

  return {
    date,
    day,
    month,
    timestamp,
  };
}

export function getMonthStr(monthValue) {
  return monthMap[Math.max(Math.min(11, monthValue), 0)];
}

export function getMonthDetails(year, month) {
  const firstDay = new Date(year, month).getDay();
  const numberOfDays = getNumberOfDays(year, month);
  const monthArray = [];
  const rows = 6;
  let currentDay = null;
  let index = 0;
  const cols = 7;

  for (let row = 0; row < rows; row += 1) {
    for (let col = 0; col < cols; col += 1) {
      currentDay = getDayDetails({
        index,
        numberOfDays,
        firstDay,
        year,
        month,
      });
      monthArray.push(currentDay);
      index += 1;
    }
  }
  return monthArray;
}

export function getDateFromDateString(value) {
  const dateData = value.split('/').map(d => parseInt(d, 10));
  if (dateData.length < 3) return null;

  const yearValue = dateData[2];
  const monthValue = dateData[0];
  const dayValue = dateData[1];
  return { yearValue, monthValue, dayValue };
}

export function getDateStringFromTimestamp(timestamp) {
  if (!timestamp) return '';
  const dateObject = new Date(timestamp);
  const monthValue = dateObject.getMonth() + 1;
  const dateValue = dateObject.getDate();
  return `${monthValue < 10 ? `0${monthValue}` : monthValue}/${
    dateValue < 10 ? `0${dateValue}` : dateValue
  }/${dateObject.getFullYear()}`;
}

export function returnEvent(value) {
  return { target: { value } };
}

export function handleDateFormat(e) {
  if (!e.ctrlKey && !e.metaKey && (e.keyCode === 32 || e.keyCode > 46)) {
    e.target.value = formatDateFromTextInput(e.target.value);
  }
}
