import { useQueryClient } from 'react-query';

import { analyticsIdentify } from '@pumpkincare/analytics';
import { AUTH_VET_LS_KEY, setIsLoggedIn } from '@pumpkincare/portal-shared';

import { VET_USER_QUERY, VET_USER_SELF_QUERY } from './user-self-query';
import { getVetUser, getVetUserSelf, logIn, logOut } from './user-service';

export function useVetLogOut() {
  const queryClient = useQueryClient();

  return function () {
    return logOut()
      .then(() => {
        setIsLoggedIn(AUTH_VET_LS_KEY, false);

        queryClient.resetQueries([VET_USER_QUERY]);
        queryClient.resetQueries([VET_USER_SELF_QUERY]);
      })
      .catch(error => {
        setIsLoggedIn(AUTH_VET_LS_KEY, true);

        throw error;
      });
  };
}

export function useVetLogIn() {
  const queryClient = useQueryClient();

  return function (login, password) {
    return logIn(login, password)
      .then(() => {
        setIsLoggedIn(AUTH_VET_LS_KEY, true);

        return getVetUserSelf();
      })
      .then(self => {
        queryClient.setQueryData([VET_USER_SELF_QUERY], self);

        return getVetUser(self.id);
      })
      .then(user => {
        queryClient.setQueryData([VET_USER_QUERY], user);
        analyticsIdentify({
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
          accountId: user.vet_practice_id,
        });

        return user;
      })
      .catch(error => {
        setIsLoggedIn(AUTH_VET_LS_KEY, false);

        throw error;
      });
  };
}
