import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '../../typography.module.css';

function Link({ children, className = '', href, ...rest }) {
  return (
    <a className={classNames(Typography.link, className)} {...rest} href={href}>
      {children}
    </a>
  );
}

Link.propTypes = {
  'aria-level': PropTypes.string,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  id: PropTypes.string,
  itemProp: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  rel: PropTypes.string,
  style: PropTypes.object,
  href: PropTypes.string,
};

export default Link;
