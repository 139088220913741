import { useQuery } from 'react-query';

import { POLICY_BASE_API } from '@pumpkincare/portal-config';
import {
  getQuotePetAge,
  getQuotePolicyZipCode,
  useQuote,
} from '@pumpkincare/portal-quotes';
import { fetchWrapper } from '@pumpkincare/portal-shared';

import { transformPolicyOptions } from './plan-utils';

export const POLICY_OPTIONS_QUERY = 'policyOptions';

export function usePolicyOptions({ quoteId, petId }) {
  const { data: quoteData } = useQuote({ quoteId });
  const petAge = getQuotePetAge(quoteData)(petId);
  const policyZipCode = getQuotePolicyZipCode(quoteData);

  return useQuery(
    [POLICY_OPTIONS_QUERY, quoteId, petId],

    () =>
      fetchWrapper(`/quotes-v3/${quoteId}/pets/${petId}/policy-options`, {
        baseApi: POLICY_BASE_API,
        transformData: transformPolicyOptions,
      }),

    {
      // we need to know quote policy / zip and pet to be complete before getting policy options
      enabled: !!quoteId && !!petId && !!policyZipCode && !!petAge,
      staleTime: Infinity,
    }
  );
}
