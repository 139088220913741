import { useQuery } from 'react-query';

import { CLOUDFLARE_BASE_API } from '@pumpkincare/portal-config';
import { fetchWrapper } from '@pumpkincare/portal-shared';

const DASHBOARD_VETS_QUERY = 'dashboard/vets';
const DASHBOARD_MEMES_QUERY = 'dashboard/memes';
const DASHBOARD_QUIZZES_QUERY = 'dashboard/quizzes';
const DASHBOARD_FACTS_QUERY = 'dashboard/facts';

/**
 * Fetch dashboard-related content for a particular vetId from Cloudflare
 * @param {string} vetId
 * @param {object} options - refer to RQ documentation on query options
 */
export function useDashboardVets(vetId, options = {}) {
  return useQuery(
    [DASHBOARD_VETS_QUERY, vetId],
    () =>
      fetchWrapper(`/portal/dashboard/vets/${vetId}`, {
        baseApi: CLOUDFLARE_BASE_API,
      }),
    {
      ...options,
      enabled: !!vetId,
      placeholderData: {},
      keepPreviousData: true,
      refetchInterval: 1000 * 60 * 60 * 3,
    }
  );
}

/**
 * Fetch dashboard-related memes from Cloudflare
 * @param {number} index
 * @param {object} options - refer to RQ documentation on query options
 */
export function useDashboardMemes(index = 0, options = {}) {
  return useQuery(
    [DASHBOARD_MEMES_QUERY, index],
    () =>
      fetchWrapper(`/portal/dashboard/memes?index=${index}`, {
        baseApi: CLOUDFLARE_BASE_API,
      }),
    {
      ...options,
      placeholderData: {},
      keepPreviousData: true,
    }
  );
}

/**
 * Fetch dashboard-related quizzes from Cloudflare
 * @param {number} index
 * @param {object} options - refer to RQ documentation on query options
 */
export function useDashboardQuizzes(index = 0, options = {}) {
  return useQuery(
    [DASHBOARD_QUIZZES_QUERY, index],
    () =>
      fetchWrapper(`/portal/dashboard/quizzes?index=${index}`, {
        baseApi: CLOUDFLARE_BASE_API,
      }),
    {
      ...options,
      placeholderData: {},
      keepPreviousData: true,
    }
  );
}

/**
 * Fetch dashboard-related facts from Cloudflare
 * @param {number} index
 * @param {object} options - refer to RQ documentation on query options
 */
export function useDashboardFacts(index = 0, options = {}) {
  return useQuery(
    [DASHBOARD_FACTS_QUERY, index],
    () =>
      fetchWrapper(`/portal/dashboard/facts?index=${index}`, {
        baseApi: CLOUDFLARE_BASE_API,
      }),
    {
      ...options,
      placeholderData: {},
      keepPreviousData: true,
    }
  );
}
