import { useDisableScroll, useToggleState } from '@pumpkincare/portal-shared';

import Button from '../button';
import Processing from '../processing';

import styles from './preview-iframe.css';

import closeIcon from './close-blue.svg';
import fileTextIcon from './file-text.svg';

export default function Iframe({ content, name, onClose = () => {} }) {
  const [isIframeLoaded, toggleIsIframeLoaded] = useToggleState(false);

  useDisableScroll();

  return (
    <div className={styles.backdrop}>
      {!isIframeLoaded ? (
        <Processing classes={{ container: styles.loading, ring: styles.spinner }} />
      ) : null}
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.name}>
            <img src={fileTextIcon} alt='' /> Preview: {name}
          </div>
          <Button className={styles.exit} onClick={onClose}>
            <img src={closeIcon} alt='' />
            <span>Exit Preview</span>
          </Button>
        </div>
        <div className={styles.content}>
          <iframe
            title='Preview Iframe'
            src={content}
            className={styles.iframe}
            onLoad={toggleIsIframeLoaded}
          />
        </div>
      </div>
    </div>
  );
}
