// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".radio-group_inputs_ab50d577 {\n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: column;\n    gap: 16px 0;\n}\n\n.radio-group_row_3d9107d3 {\n    flex-direction: row;\n}\n\n.radio-group_inputs_ab50d577.radio-group_row_3d9107d3 {\n    gap: 0 16px;\n}\n\n.radio-group_error_f58ce2ef {\n    color: var(--crookshanksRed);\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 20px;\n    flex: 100%;\n    margin-top: 10px;\n}", "",{"version":3,"sources":["webpack://../../libs/portal-ui/src/radio-group/radio-group.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,4BAA4B;IAC5B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,UAAU;IACV,gBAAgB;AACpB","sourcesContent":[".inputs {\n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: column;\n    gap: 16px 0;\n}\n\n.row {\n    flex-direction: row;\n}\n\n.inputs.row {\n    gap: 0 16px;\n}\n\n.error {\n    color: var(--crookshanksRed);\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 20px;\n    flex: 100%;\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputs": "radio-group_inputs_ab50d577",
	"row": "radio-group_row_3d9107d3",
	"error": "radio-group_error_f58ce2ef"
};
export default ___CSS_LOADER_EXPORT___;
