// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".legal-body_legalBody_d9e8ab9a {\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 20px;\n  color: var(--scoutNavy);\n}", "",{"version":3,"sources":["webpack://../../libs/portal-ui/src/legal-body/legal-body.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,uBAAuB;AACzB","sourcesContent":[".legalBody {\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 20px;\n  color: var(--scoutNavy);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legalBody": "legal-body_legalBody_d9e8ab9a"
};
export default ___CSS_LOADER_EXPORT___;
