import SvgIcon, { SvgIconPropTypes } from './svg-icon';

function Edit(props) {
  const { width, height } = props;

  return (
    <SvgIcon {...props} title='edit'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.5894 1.07745L18.9228 4.41078C19.2482 4.73622 19.2482 5.26386 18.9228 5.5893L10.5894 13.9226C10.4331 14.0789 10.2212 14.1667 10.0002 14.1667H6.66683C6.20659 14.1667 5.8335 13.7936 5.8335 13.3334V10C5.8335 9.77903 5.92129 9.56706 6.07757 9.41078L14.4109 1.07745C14.7363 0.752015 15.264 0.752015 15.5894 1.07745ZM15.8335 12.2167V16.6667C15.8335 17.1269 15.4604 17.5 15.0002 17.5H3.3335C2.87326 17.5 2.50016 17.1269 2.50016 16.6667V5.00004C2.50016 4.5398 2.87326 4.16671 3.3335 4.16671H7.7835C8.24373 4.16671 8.61683 3.79361 8.61683 3.33337C8.61683 2.87314 8.24373 2.50004 7.7835 2.50004H3.3335C1.95278 2.50004 0.833496 3.61933 0.833496 5.00004V16.6667C0.833496 18.0474 1.95278 19.1667 3.3335 19.1667H15.0002C16.3809 19.1667 17.5002 18.0474 17.5002 16.6667V12.2167C17.5002 11.7565 17.1271 11.3834 16.6668 11.3834C16.2066 11.3834 15.8335 11.7565 15.8335 12.2167ZM7.50016 12.4999V10.3451L15.0002 2.84509L17.155 4.99992L9.65499 12.4999H7.50016Z'
        fill='#373F51'
      />
      <mask
        id='mask0_9881_322'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width={width}
        height={height}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.5894 1.07745L18.9228 4.41078C19.2482 4.73622 19.2482 5.26386 18.9228 5.5893L10.5894 13.9226C10.4331 14.0789 10.2212 14.1667 10.0002 14.1667H6.66683C6.20659 14.1667 5.8335 13.7936 5.8335 13.3334V10C5.8335 9.77903 5.92129 9.56706 6.07757 9.41078L14.4109 1.07745C14.7363 0.752015 15.264 0.752015 15.5894 1.07745ZM15.8335 12.2167V16.6667C15.8335 17.1269 15.4604 17.5 15.0002 17.5H3.3335C2.87326 17.5 2.50016 17.1269 2.50016 16.6667V5.00004C2.50016 4.5398 2.87326 4.16671 3.3335 4.16671H7.7835C8.24373 4.16671 8.61683 3.79361 8.61683 3.33337C8.61683 2.87314 8.24373 2.50004 7.7835 2.50004H3.3335C1.95278 2.50004 0.833496 3.61933 0.833496 5.00004V16.6667C0.833496 18.0474 1.95278 19.1667 3.3335 19.1667H15.0002C16.3809 19.1667 17.5002 18.0474 17.5002 16.6667V12.2167C17.5002 11.7565 17.1271 11.3834 16.6668 11.3834C16.2066 11.3834 15.8335 11.7565 15.8335 12.2167ZM7.50016 12.4999V10.3451L15.0002 2.84509L17.155 4.99992L9.65499 12.4999H7.50016Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_9881_322)'>
        <rect width={width} height={height} fill='#373F51' />
      </g>
    </SvgIcon>
  );
}

Edit.propTypes = SvgIconPropTypes;

Edit.defaultProps = {
  width: 16,
  height: 16,
};

export default Edit;
