import { useState } from 'react';
import { default as ReactSelect } from 'react-select';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import LegalBody from '../legal-body';

import Typography from '../typography.css';
import styles from './select.css';

function getColor(state, error) {
  if (error) return 'var(--crookshanksRed)';
  if (state.isFocused) return 'var(--bluesCluesBlue)';
  if (state.hasValue) return 'var(--scoutNavy)';
  return 'var(--pusheenGray)';
}

function Select({
  classes,
  customStyles,
  onChange,
  defaultValue,
  label,
  id,
  error: { errorMessage = '', isErrorHidden = true },
  ...restInputProps
}) {
  // react-select uses emotion for styling
  const selectStyles = {
    input: () => ({
      transform: 'translateY(-50%)',
      top: '50%',
      color: 'var(--scoutNavy)',
      position: 'absolute',
      width: '100%',
      padding: '0px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      cursor: 'pointer !important',
    }),
    // reset styles for valueContainer
    valueContainer: () => ({
      minHeight: '22px',
    }),
    menu: provided => ({
      ...provided,
      width: '100%',
      maxHeight: '240px',
      marginTop: '4px',
      marginBottom: '8px',
      zIndex: 10000,
      overflowY: 'scroll',
    }),
    loadingIndicator: provided => ({
      ...provided,
      position: 'absolute',
      right: '32px',
      top: '50%',
      transform: 'translateY(-50%)',
    }),
    placeholder: () => ({
      position: 'absolute',
      width: '100%',
      cursor: 'pointer !important',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '22px',
      color: 'var(--gromitGray)',
      ...customStyles.placeholder,
    }),
    singleValue: () => ({
      color: getColor({ hasValue: true }, errorMessage),
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      display: 'inline-block',
      ...customStyles.singleValue,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => {
      return {
        width: '24px',
        display: 'inline-block',
        position: 'absolute',
        right: '2px',
        top: '50%',
        transform: 'translateY(-35%)',
        color: getColor(state, errorMessage),
        cursor: 'pointer !important',
        ...customStyles.dropdownIndicator,
      };
    },
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Nunito Sans',
      paddingBottom: '14px',
      paddingTop: '14px',
      color: state.isFocused ? 'var(--dinoBlue)' : 'var(--scoutNavy)',
      background: 'none',
      cursor: 'pointer',
    }),
    control: (provided, state) => ({
      borderWidth: state.isFocused || !!errorMessage ? '2px' : '1px',
      borderStyle: 'solid',
      borderColor: getColor(state, errorMessage),
      borderRadius: '4px',
      padding: state.isFocused ? '11px' : '12px',
      display: 'flex',
      background: 'var(--zeroWhite)',
      ...customStyles.control,
    }),
  };

  const [isSelected, setSelected] = useState(!!defaultValue);

  function handleChange(option) {
    if (option) {
      setSelected(true);
    }

    onChange(option);
  }

  return (
    <div className={classNames(styles.wrapper, classes.wrapper)}>
      <label
        htmlFor={id}
        className={classNames(styles.label, Typography.legalBody, {
          [styles.visible]: isSelected,
        })}
      >
        {label}
      </label>

      <ReactSelect
        styles={selectStyles}
        onChange={handleChange}
        onInputChange={() => {}}
        controlShouldRenderValue
        isClearable={false}
        label={label}
        defaultValue={defaultValue}
        aria-label={label}
        inputId={id}
        {...restInputProps}
      />

      {!isErrorHidden || errorMessage ? (
        <LegalBody className={styles.errorMessage}>{errorMessage}</LegalBody>
      ) : null}
    </div>
  );
}

Select.defaultProps = {
  isSearchable: false,
  classes: {},
  customStyles: {},
  components: {},
  label: '',
  autoFocus: false,
  tabIndex: 0,
  onChange: () => {},
  error: {},
};

Select.propTypes = {
  classes: PropTypes.object,
  customStyles: PropTypes.object,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  label: PropTypes.string,
  id: PropTypes.string,

  // ...restInputProps you may need (or are required)
  autoFocus: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  components: PropTypes.object,
  hideSelectedOptions: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.any,
  tabIndex: PropTypes.number,
  value: PropTypes.any,
};

export default Select;
