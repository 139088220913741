import classNames from 'classnames';

import Button from '../button';

import styles from './file-input.css';

export default function FileInput({
  accept = 'image/*',
  children,
  classes = {},
  disabled,
  handleImageChange,
  inputProps = {},
}) {
  return (
    <Button className={classNames(styles.container, classes.container)}>
      {children}
      <input
        type='file'
        accept={accept}
        disabled={disabled}
        className={classNames(styles.input, classes.input, {
          [styles.disabled]: disabled,
        })}
        onChange={handleImageChange}
        data-testid='file-input'
        {...inputProps}
      />
    </Button>
  );
}
