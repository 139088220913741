import { trackShareToSocial } from '@pumpkincare/analytics';
import { FB_APP_KEY } from '@pumpkincare/portal-config';

export function facebookShare(url, options) {
  // hashtag should be a single string with the preceding # character
  const quoteParam = options?.quote
    ? `&quote=${encodeURIComponent(options.quote)}`
    : '';
  const hashtagParam = options?.hashtag
    ? `&hashtag=${encodeURIComponent(options.hashtag)}`
    : '';
  window.open(
    `https://www.facebook.com/dialog/share?app_id=${FB_APP_KEY}&display=popup&href=${encodeURIComponent(
      url
    )}${quoteParam}${hashtagParam}`,
    'targetWindow',
    `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=400`
  );
}

export function twitterShare(url, options) {
  // hashtags should be comma-separated list and without the preceding # character
  const textParam = options?.text ? `&text=${encodeURIComponent(options.text)}` : '';
  const hashtagsParam = options?.hashtags
    ? `&hashtags=${encodeURIComponent(options.hashtags)}`
    : '';
  window.open(
    `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      url
    )}${textParam}${hashtagsParam}`,
    'targetWindow',
    `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=400`
  );
}

export function linkedinShare(url) {
  window.open(
    `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`,
    'targetWindow',
    `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=400`
  );
}

export function handleShareSocialChannel(content, vetName, channel) {
  trackShareToSocial(content.analyticsCategory, content.name, channel);
  const vetUrl = `${content.url}?vet_name=${encodeURIComponent(vetName)}`;
  const handlers = {
    Facebook: () => facebookShare(vetUrl, { quote: content.facebookQuote }),
    Twitter: () => twitterShare(vetUrl, { text: content.twitterQuote }),
    LinkedIn: () => linkedinShare(vetUrl),
  };

  if (handlers[channel]) handlers[channel]();
}
