import BodySubhead from '../body-subhead';
import SubText1 from '../sub-text1';

import styles from './password-requirements.css';

export default function PasswordRequirements({ validation }) {
  const {
    hasAtLeast12Char,
    hasAtLeastOneLowercase,
    hasAtLeastOneUppercase,
    hasAtLeastOneDigit,
    hasAtLeastOneSpecialChar,
  } = validation;

  function renderIcon(isValid) {
    return <span className={styles.icon}>{isValid ? '✓' : '✕'}</span>;
  }

  return (
    <>
      <BodySubhead style={{ marginBottom: '16px' }}>
        Passwords must include:
      </BodySubhead>

      <div className={hasAtLeast12Char ? styles.green : styles.red}>
        {renderIcon(hasAtLeast12Char)}
        <SubText1 className={styles.copy}>At least 12 characters in length</SubText1>
      </div>

      <div
        className={
          hasAtLeastOneLowercase && hasAtLeastOneUppercase
            ? styles.green
            : styles.red
        }
      >
        {renderIcon(hasAtLeastOneLowercase && hasAtLeastOneUppercase)}
        <SubText1 className={styles.copy}>
          At least one UPPERCASE & lowercase letter
        </SubText1>
      </div>

      <div className={hasAtLeastOneDigit ? styles.green : styles.red}>
        {renderIcon(hasAtLeastOneDigit)}
        <SubText1 className={styles.copy}>At least one number</SubText1>
      </div>

      <div className={hasAtLeastOneSpecialChar ? styles.green : styles.red}>
        {renderIcon(hasAtLeastOneSpecialChar)}
        <SubText1 className={styles.copy}>
          {`At least one special character, such as: ^ { } ( ) ! @ # % & ~`}
        </SubText1>
      </div>
    </>
  );
}
