import classNames from 'classnames';

import styles from './processing.css';

function Processing({ classes = {} }) {
  return (
    <div className={classes.container} data-testid='processing'>
      <div className={classNames(styles.ring, classes.ring)}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default Processing;
