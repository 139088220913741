// if you need any of these values in test, add manually to setup.jest.js
export const {
  BASE_API,
  BASE_PASSWORD,
  BASE_PORTAL_URL,
  BASE_USERNAME,
  CLOUDFLARE_BASE_API,
  FB_APP_KEY,
  QF_URL,
  LAUNCH_DARKLY_KEY,
  LOCAL_SEGMENT_KEY,
  NODE_ENV,
  PERSONALIZATION_BASE_API,
  POLICY_BASE_API,
  SEGMENT_KEY,
} = process.env;
export const AWS_AMPLIFY_SETTINGS = {
  identityPoolId: process.env.AUTH_IDENTITY_POOL_ID,
  userPoolId: process.env.AUTH_USER_POOL_ID,
  userPoolClientId: process.env.AUTH_USER_POOL_WEB_CLIENT,
};
