import { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useKeyboard } from '@pumpkincare/portal-shared';

import Body1 from '../body1';

import Typography from '../typography.css';
import styles from './modal.css';

function Modal({
  children,
  classes = {},
  disabled,
  stickToTheRight,
  onClose,
  title,
  subTitle,
  hasCloseIcon,
  leftAligned,
  ...rest
}) {
  const containerClassName = classNames(styles.container, classes.container, {
    [styles.containerStickToTheRightMobile]: stickToTheRight,
  });

  const contentClassName = classNames(styles.content, classes.content);
  const modalClass = classNames(styles.modal, classes.modal);
  const backDropClass = classNames(styles.backdrop, classes.backdrop);
  const modalDialogClass = classNames(styles.modalDialog, classes.modalDialog);
  const modalHeaderClass = classNames(styles.modalHeader, classes.modalHeader, {
    [styles.leftAligned]: leftAligned,
  });

  function handleClose(type) {
    if (!disabled) {
      onClose(type);
    }
  }

  useKeyboard(
    {
      Escape: handleClose,
    },
    [0]
  );

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return function cleanup() {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <>
      <div
        className={modalClass}
        onClick={event =>
          event.currentTarget === event.target && handleClose('backdrop')
        }
      >
        <div className={modalDialogClass} role='dialog'>
          {hasCloseIcon ? (
            <div className={styles.closeButtonContainer}>
              <button
                className={styles.closeButton}
                onClick={() => handleClose('icon')}
                aria-label='closeModal'
              />
            </div>
          ) : null}

          <div
            className={containerClassName}
            onClick={e => e.stopPropagation()}
            {...rest}
          >
            {title ? (
              <div className={modalHeaderClass}>
                <span className={classNames(Typography.h3, styles.modalTitle)}>
                  {title}
                </span>

                <Body1 className={styles.modalSubTitle}>{subTitle}</Body1>
              </div>
            ) : null}

            <div className={contentClassName}>{children}</div>
          </div>
        </div>
      </div>

      <div
        className={backDropClass}
        data-testid='backdrop-div'
        onClick={() => handleClose('backdrop')}
      />
    </>
  );
}

Modal.defaultProps = {
  classes: {},
  disabled: false,
  stickToTheRight: false,
  leftAligned: false,
  title: '',
  subTitle: '',
  hasCloseIcon: true,
};

Modal.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    backdrop: PropTypes.string,
    modal: PropTypes.string,
    modalHeader: PropTypes.string,
    modalBody: PropTypes.string,
    modalDialog: PropTypes.string,
  }),
  leftAligned: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,

  disabled: PropTypes.bool,
  onClose: (props, propName, componentName) => {
    if (props.disabled !== true && typeof props[propName] !== 'function') {
      return new Error(
        `Invalid prop ${propName} passed to ${componentName}. Expected a function if Modal is not disabled`
      );
    }
  },
  stickToTheRight: PropTypes.bool,
  hasCloseIcon: PropTypes.bool,

  // spread via ...rest
  'aria-labelledby': PropTypes.string,
  'aria-describedby': PropTypes.string,
  'aria-label': PropTypes.string,
};

export default Modal;
