import classNames from 'classnames';
import PropTypes from 'prop-types';

import Spinner from '../../informational/spinner';

import ButtonStyles from '../../button.module.css';

const colorStyles = {
  primary: ButtonStyles.primary,
  primaryLarge: ButtonStyles.primaryLarge,
  secondary: ButtonStyles.secondary,
  tertiary: ButtonStyles.tertiary,
  default: ButtonStyles.baseButton,
};

function LoaderButton({ classes, children, isLoading, disabled, color, ...rest }) {
  return (
    <button
      className={classNames(colorStyles[color], classes.root)}
      disabled={disabled || isLoading}
      {...rest}
    >
      {isLoading ? (
        <Spinner classes={{ spinner: ButtonStyles.spinner }} size={25} />
      ) : (
        children
      )}
    </button>
  );
}

LoaderButton.defaultProps = {
  classes: {},
  disabled: false,
  isLoading: false,
  color: 'default',
  tabIndex: 0,
  autoFocus: false,
};

LoaderButton.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  color: PropTypes.oneOf([
    'primary',
    'primaryLarge',
    'secondary',
    'tertiary',
    'default',
  ]),

  // props for autocomplete from ...rest
  onClick: PropTypes.func,
  style: PropTypes.object,
  type: PropTypes.string,
  role: PropTypes.string,
  'aria-label': PropTypes.string,
  'data-testid': PropTypes.string,
  tabIndex: PropTypes.number,
  autoFocus: PropTypes.bool,
};

export default LoaderButton;
