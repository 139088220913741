import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './pagination.css';

function Pagination(props) {
  const { range, count, currentPage, onPageChange, classes } = props;

  const { from, to } = range;

  const prevClassName = classNames(styles.prevIcon, {
    [styles.disabled]: from <= 1,
  });

  const nextClassName = classNames({
    [styles.disabled]: to + 1 >= count,
  });

  function handlePrevClick() {
    onPageChange(currentPage - 1);
  }

  function handleNextClick() {
    onPageChange(currentPage + 1);
  }

  return (
    <div className={classNames(styles.root, classes.root)}>
      <span>{`${from}-${to} of ${count}`}</span>

      <div className={styles.buttonWrapper}>
        <button
          onClick={handlePrevClick}
          disabled={from <= 1}
          aria-label='previous page'
        >
          <img
            className={prevClassName}
            src='/assets/images/iconImages/arrow-right.svg'
          />
        </button>
        <button
          onClick={handleNextClick}
          disabled={to + 1 >= count}
          aria-label='next page'
        >
          <img
            className={nextClassName}
            src='/assets/images/iconImages/arrow-right.svg'
          />
        </button>
      </div>
    </div>
  );
}

Pagination.defaultProps = {
  classes: {},
  onPageChange: () => {},
  range: { from: 1, to: 1 },
  count: 1,
  currentPage: 1,
};

Pagination.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),

  /** tells the user where in the array they currently are (e.g 11-20) */
  range: PropTypes.shape({
    from: PropTypes.number,
    to: PropTypes.number,
  }),
  /** the number of items that are to be rendered on all pages */
  count: PropTypes.number,
  /** where in the pagination the user currently is */
  currentPage: PropTypes.number,
  /** not only the pagination act, but any side effects you might want as well */
  onPageChange: PropTypes.func,
};

export default Pagination;
