import PropTypes from 'prop-types';

import styles from './svg-icon.module.css';

function SvgIcon({ children, className, color, size = 24, width, height, ...rest }) {
  return (
    <svg
      fill={color}
      width={width || size}
      height={height || size}
      focusable='false'
      viewBox={`0 0 ${width || size} ${height || size}`}
      className={`${styles.root} ${className}`}
      {...rest}
    >
      {children}
    </svg>
  );
}

export const SvgIconPropTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
  title: PropTypes.string,
  'data-testid': PropTypes.string,
};

SvgIcon.propTypes = SvgIconPropTypes;

export default SvgIcon;
