import { useState } from 'react';

function validateValue(value) {
  return !!value;
}

export default function useTargetState(
  initialValue = '',
  validationFunc = validateValue
) {
  const [value, setValue] = useState(initialValue);

  const setTargetsValue = e => {
    setValue(e.target.value);
  };

  const isValid = validationFunc(value);

  return [value, setTargetsValue, isValid];
}
