export function formatSearchFromQuery(query) {
  return Object.keys(query)
    .filter(key => !!query[key] || query[key] === 0)
    .map(key => `${key}=${encodeURIComponent(query[key])}`)
    .join('&');
}

export function filterUTMParams(search) {
  return search
    .split(/(\?|&)/) // regex to split by ? and &
    .filter(item => item.match(/utm_/))
    .join('&');
}

export function formatDateFromTextInput(string) {
  const format = 'mm/dd/yyyy';
  const match = new RegExp(
    format
      .replace(/(\w+)\W(\w+)\W(\w+)/, '^\\s*($1)\\W*($2)?\\W*($3)?([0-9]*).*')
      .replace(/m|d|y/g, '\\d')
  );
  const replace = '$1/$2/$3$4'.replace(/\//g, format.match(/\W/));

  return string
    .replace(/(^|\W)(?=\d\W)/g, '$10')
    .replace(match, replace)
    .replace(/(\W)+/g, '$1')
    .substr(0, 10);
}

/*
 * Formats a valid phone number to (xxx) xxx-xxxx, otherwise returns original input
 * @param {string} str - string to be formatted
 */
export function formatPhoneNumber(str) {
  // this returns a clean string with only numbers and a + sign for an international code
  // 99.5% of our BE stored phones are 12 characters long with a +1 in front
  const international = `${str}`.replace(/[^+\d]/g, '');
  const hasPlus = !!international.match(/^\+/);

  // international numbers on FE are more of a edge case, so we're not going to handle them for now with a heavy library
  const cleaned = hasPlus ? international.slice(2) : international;

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : str;
}
