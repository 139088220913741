import { useCallback, useEffect } from 'react';

// https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
export default function useDisableScroll({
  callback = () => {},
  shouldDisableOnLoad = true,
} = {}) {
  const preventNavigationByKeys = useCallback(e => {
    if (
      ['Space', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].some(
        key => key === e.code
      )
    ) {
      e.preventDefault();
    }
  }, []);

  const disableScroll = useCallback(
    isKeysOnly => {
      if (isKeysOnly) {
        window.addEventListener('keydown', preventNavigationByKeys);
      } else {
        const width = window.innerWidth;
        document.body.style.position = 'fixed';
        document.body.style.overflow = 'hidden';
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.left = `${Math.max((width - 1440) / 2, 0)}px`;
      }
      callback(true);
    },
    [callback, preventNavigationByKeys]
  );

  const enableScroll = useCallback(
    isKeysOnly => {
      if (isKeysOnly) {
        window.removeEventListener('keydown', preventNavigationByKeys);
      } else {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.overflow = '';
        document.body.style.top = '';
        document.body.style.left = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
      callback(false);
    },
    [callback, preventNavigationByKeys]
  );

  useEffect(() => {
    const isFixed = document.body.style.position === 'fixed';

    // to deal with duped uses of useDisableScroll (e.g. nested modals)
    if (!isFixed && shouldDisableOnLoad) {
      disableScroll();
      return enableScroll;
    }
  }, [shouldDisableOnLoad, disableScroll, enableScroll]);

  return { disableScroll, enableScroll };
}
