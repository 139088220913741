import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './radio.css';

function Radio({
  classes = {},
  style = {},
  label = '',
  onChange,
  error: { hasError, errorMessage = '' } = {},
  small,
  align = 'left',
  variant = 'default',
  'data-testid': dataTestId,
  ...restInputProps
}) {
  const rootClassName = classNames(styles.root, classes.root, {
    [styles.portal]: variant === 'portal',
    [classNames(styles.checked, classes.checked)]: restInputProps.checked,
    [styles.error]: hasError || !!errorMessage,
  });
  const radioClassName = classNames(classes.radio, styles.radio, {
    [styles.smallRadio]: small,
  });
  const labelClassName = classNames(styles.label, classes.label, {
    [styles.medium]: !small,
    [styles.small]: small,
    [styles.center]: align === 'center',
  });
  const imageClassName = classNames(styles.image, classes.image);

  return (
    <label
      className={rootClassName}
      style={style}
      onChange={onChange}
      data-testid={dataTestId}
    >
      <input
        className={radioClassName}
        type='radio'
        onChange={() => {}}
        {...restInputProps}
      />
      <div className={imageClassName} aria-hidden />
      <span className={labelClassName}>{label}</span>
    </label>
  );
}

Radio.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
    radio: PropTypes.string,
    checked: PropTypes.string,
    image: PropTypes.string,
  }),

  align: PropTypes.oneOf(['left', 'center']),
  name: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func,
  /** a cute small radio button */
  small: PropTypes.bool,
  style: PropTypes.object,
  checked: PropTypes.bool,
  hasError: PropTypes.bool,
  'data-testid': PropTypes.string,
  /** ADA related - you should wrap Radio in a RadioGroup and let it handle this! */
  'aria-checked': PropTypes.bool,
  /** ADA related - you should wrap Radio in a RadioGroup and let it handle this! */
  tabIndex: PropTypes.number,
  variant: PropTypes.oneOf(['default', 'portal']),
};

export default Radio;
