import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './slide-out-panel.css';

import closeIcon from './icon_closebg.png';
import hoverIcon from './icon_closebg_hover.png';

function SlideOutPanel({ children, direction, onClose, hasClose, show, classes }) {
  return (
    <>
      <div
        className={classNames(
          styles.backdrop,
          !show ? styles.hideBackDrop : styles.showBackDrop
        )}
        onClick={onClose}
        data-testid={'backdrop'}
      >
        &nbsp;
      </div>
      <div
        className={classNames(
          styles.container,
          classes.container,
          !show ? styles.hideContainer : styles.showContainer,
          { [styles.left]: direction === 'left' }
        )}
        data-testid='slide-out-panel'
      >
        {hasClose ? (
          <button
            className={styles.close}
            onClick={onClose}
            aria-label='Close Panel'
          >
            <img src={closeIcon} alt='' />
            <img src={hoverIcon} alt='' />
          </button>
        ) : null}

        {children}
      </div>
    </>
  );
}

SlideOutPanel.propTypes = {
  classes: PropTypes.shape({ container: PropTypes.string }),

  children: PropTypes.node,
  /** Control whether the panel slides out from left or right */
  direction: PropTypes.oneOf(['left', 'right']),
  onClose: PropTypes.func.isRequired,
  /** Use if you want an explicit to handle closing */
  hasClose: PropTypes.bool,
  /** Set the panel to either be in view or not */
  show: PropTypes.bool,
};

SlideOutPanel.defaultProps = {
  classes: {},
  direction: 'right',
  show: false,
};

export default SlideOutPanel;
