import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Body1 from '../body1';
import LegalBody from '../legal-body';
import Processing from '../processing';

import styles from './file-upload.css';

import arrowUp from './arrow-up.svg';
import checkCircleFilled from './check-circle-filled.png';
import fileText from './file-text.svg';

function getFileExtension(filename) {
  return /\.[^.]+$/.exec(filename)[0];
}

function FileUpload({
  acceptedFilesText,
  classes,
  error,
  files,
  maxFileSize,
  onError,
  onFileListChange,
  onIsUploadingChange,
  onUpload,
}) {
  const [fetching, setIsFetching] = useState(false);
  const [uploadErrorMsg, setUploadErrorMsg] = useState('');

  const queuedFiles = [];
  const completedFiles = [];

  function onFileUpload(event, dropped) {
    onError('');
    setUploadErrorMsg('');
    queuedFiles.length = 0;
    completedFiles.length = 0;

    const filesToUpload = dropped ? event.dataTransfer.files : event.target.files;

    if (!filesToUpload) {
      return;
    }

    [...filesToUpload].forEach((fileToUpload, i) => {
      let isValid = true;
      if (fileToUpload.size > maxFileSize.raw) {
        isValid = false;
        setUploadErrorMsg(
          'This file exceeds the max file size. Please resize and try again.'
        );
      }

      if (fileToUpload.size === 0) {
        isValid = false;
        setUploadErrorMsg(
          'This file seems to be empty. Please double-check or choose another file and try again.'
        );
      }

      if (
        !acceptedFilesText
          .replace(/ /g, '')
          .split(',')
          .includes(getFileExtension(fileToUpload.name.toLowerCase()))
      ) {
        isValid = false;
        setUploadErrorMsg(
          'This file format is not accepted. Please choose another file and try again.'
        );
      }

      if (files.some(file => file.name === fileToUpload.name)) {
        isValid = false;
        setUploadErrorMsg(
          'This file is already uploaded. Please choose another file and try again.'
        );
      }

      if (isValid) {
        const reader = new FileReader();

        reader.onload = () => {
          queuedFiles.push(fileToUpload.name);

          setIsFetching(true);
          onIsUploadingChange(true);

          let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
          if (encoded.length % 4 > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4));
          }
          fileToUpload.content = encoded;

          onUpload(
            { name: fileToUpload.name, type: fileToUpload.type, description: '' },
            fileToUpload
          )
            .then(response => {
              onFileListChange(prev => [
                ...prev,
                {
                  key: fileToUpload.key,
                  name: fileToUpload.name,
                  type: fileToUpload.type,
                  signedUrls: response.signedUrls,
                },
              ]);
            })
            .catch(() => {
              setUploadErrorMsg('There was an error while uploading this file.');
              event.target.value = '';
            })
            .finally(() => {
              completedFiles.push(fileToUpload.name);

              if (completedFiles.length === queuedFiles.length) {
                setIsFetching(false);
                onIsUploadingChange(false);
              }
            });
        };

        reader.readAsDataURL(fileToUpload);
      }
    });
  }

  function dropHandler(event) {
    event.preventDefault();
    onFileUpload(event, true);
  }

  function dragOverHandler(event) {
    event.preventDefault();
  }

  function handleDelete(uploadedFile) {
    onError('');
    onFileListChange(files.filter(file => file.name !== uploadedFile.name));
  }

  return (
    <div
      className={classNames(
        error ? styles.uploadDivError : styles.uploadDiv,
        {
          [styles.uploadDivWithFiles]: files.length,
        },
        classes.root
      )}
      onDrop={dropHandler}
      onDragOver={dragOverHandler}
    >
      <label className={styles.labelUpload}>
        {fetching ? (
          <div className={styles.processing}>
            <Processing />
          </div>
        ) : (
          <>
            <div className={styles.buttonUploadCircle}>
              <img src={arrowUp} alt='upload button' />
            </div>

            <Body1 className={styles.uploadButtonContainer}>
              Drag and drop files here, or
            </Body1>
            <Body1 className={styles.browseFileText}>browse files to upload</Body1>
            <div className={styles.uploadInfoDiv}>
              <LegalBody className={styles.acceptedFormatsDiv}>
                Accepted formats: {acceptedFilesText}
              </LegalBody>
              <LegalBody className={styles.maxFileSizeDiv}>
                Max file size of {maxFileSize.formatted}
              </LegalBody>
              {error || uploadErrorMsg ? (
                <LegalBody className={styles.uploadErrorText}>
                  {error || uploadErrorMsg}
                </LegalBody>
              ) : null}
            </div>
          </>
        )}

        <input
          className={styles.uploadFile}
          type='file'
          multiple
          name='file'
          accept={acceptedFilesText}
          onChange={onFileUpload}
          data-testid='file-input'
        />
      </label>

      {files.map(uploadedFile => (
        <div key={uploadedFile.name} className={styles.uploadedFile}>
          <div className={styles.uploadedFileIcon}>
            <img src={fileText} alt='' />
            <img className={styles.checkIcon} src={checkCircleFilled} alt='' />
          </div>

          <div className={styles.uploadedFileLabel}>
            <LegalBody className={styles.uploadedFileName}>
              {uploadedFile.name}
            </LegalBody>
            <button
              onClick={() => handleDelete(uploadedFile)}
              className={classNames(styles.uploadedFileDelete, styles.baseButton)}
            >
              {/* todo replace baseButton with ButtonStyles on ButtonStyles introduction */}
              Delete
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

FileUpload.defaultProps = {
  acceptedFilesText: '.jpg, .jpeg, .pdf, .tif, .tiff, .png, .doc',
  classes: {},
  maxFileSize: { raw: 9540000, formatted: '9.54MB' },
  onError: () => {},
  onIsUploadingChange: () => {},
};

FileUpload.propTypes = {
  acceptedFilesText: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  /** there is internal error handling, this is for external errors e.g. file needed to submit claim */
  error: PropTypes.string,
  files: PropTypes.array.isRequired,
  /** raw and formatted should basically be the same number, but raw is used for determining file validity and formatted is just for rendering */
  maxFileSize: PropTypes.shape({
    raw: PropTypes.number.isRequired,
    formatted: PropTypes.string.isRequired,
  }),
  /** there is internal error handling, this is for external errors e.g. file needed to submit claim */
  onError: PropTypes.func,
  onFileListChange: PropTypes.func.isRequired,
  /** allow the parent to track the state of submitting a file e.g. block submitting a form while upload happens */
  onIsUploadingChange: PropTypes.func,
  onUpload: PropTypes.func.isRequired,
};

export default FileUpload;
