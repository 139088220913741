// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".legal-body-subhead_legalBodySubhead_d3eb7f08 {\n  font-weight: 800;\n  font-size: 14px;\n  line-height: 20px;\n  color: var(--scoutNavy);\n}", "",{"version":3,"sources":["webpack://../../libs/portal-ui/src/legal-body-subhead/legal-body-subhead.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,uBAAuB;AACzB","sourcesContent":[".legalBodySubhead {\n  font-weight: 800;\n  font-size: 14px;\n  line-height: 20px;\n  color: var(--scoutNavy);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legalBodySubhead": "legal-body-subhead_legalBodySubhead_d3eb7f08"
};
export default ___CSS_LOADER_EXPORT___;
