import { useEffect } from 'react';

export default function useKeyboard(actionMap, dependencies) {
  function handleKeyDown({ key }) {
    const action = actionMap[key];

    if (action && typeof action === 'function') {
      action();
    }
  }

  return useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, dependencies);
}
