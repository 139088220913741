import { ERROR, IDLE, SUCCESS } from '../../constants/action-statuses';

/*
  input: SET_APP_VALUE
  return: [
    'app/SET_VALUE',
    'app/SET_VALUE_ERROR',
    'app/SET_VALUE_SUCCESS',
    setAppValue(payload, status) { ... },
  ]
 */
export function asyncReduxCreator(name) {
  const parsed = name.split('_');
  const type = `${parsed[1].toLowerCase()}/${parsed[0]}_${parsed
    .slice(2)
    .join('_')}`;

  return [
    type,
    `${type}_${ERROR}`,
    `${type}_${SUCCESS}`,
    function reduxAction(payload, status = '') {
      return {
        type: `${type}${status ? `_${status}` : ''}`,
        payload,
      };
    },
  ];
}

export function asyncInitialState(data) {
  return { data, status: IDLE, error: null };
}
