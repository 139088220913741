import { useQuery } from 'react-query';

import { fetchWrapper } from '@pumpkincare/portal-shared';

export default function useFrames(vetId) {
  return useQuery(
    ['vetFrames', vetId],

    async () => fetchWrapper(`/vets/${vetId}/photo-frames`),

    {
      enabled: !!vetId,
      placeholderData: [],
      staleTime: 1000 * 60 * 60, // 1 hour in milliseconds
    }
  );
}
