// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select_root_e378e5bd {\n  width: 100%;\n  margin: auto;\n  cursor: pointer;\n}\n\n.select_label_0f2a8b75 {\n  margin-bottom: 2px;\n  display: block;\n  font-weight: 800;\n}\n\n.select_asterisk_2d6727e5 {\n  color: var(--rowlfRed);\n}\n\ndiv.select_errorMessage_0ffeb7be {\n  color: var(--rowlfRed);\n  font-size: 12px;\n  margin-top: 8px;\n  min-height: 20px;\n  font-weight: 400;\n  text-align: left;\n}", "",{"version":3,"sources":["webpack://../../libs/shared/src/ui/inputs/select/select.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".root {\n  width: 100%;\n  margin: auto;\n  cursor: pointer;\n}\n\n.label {\n  margin-bottom: 2px;\n  display: block;\n  font-weight: 800;\n}\n\n.asterisk {\n  color: var(--rowlfRed);\n}\n\ndiv.errorMessage {\n  color: var(--rowlfRed);\n  font-size: 12px;\n  margin-top: 8px;\n  min-height: 20px;\n  font-weight: 400;\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "select_root_e378e5bd",
	"label": "select_label_0f2a8b75",
	"asterisk": "select_asterisk_2d6727e5",
	"errorMessage": "select_errorMessage_0ffeb7be"
};
export default ___CSS_LOADER_EXPORT___;
