// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./green-check.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".alert_alert_10c45f72 {\n    width: 100%;\n    border-radius: 10px;\n    border-width: 1px;\n    border-style: solid;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 16px;\n}\n\n.alert_success_64da44b3 {\n    border-color: var(--primaryGreen);\n}\n\n.alert_image_7df1c6b0 {\n    margin-right: 16px;\n}\n\n.alert_success_64da44b3 .alert_image_7df1c6b0 {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}", "",{"version":3,"sources":["webpack://../../libs/portal-ui/src/alert/alert.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,yDAAwC;AAC5C","sourcesContent":[".alert {\n    width: 100%;\n    border-radius: 10px;\n    border-width: 1px;\n    border-style: solid;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 16px;\n}\n\n.success {\n    border-color: var(--primaryGreen);\n}\n\n.image {\n    margin-right: 16px;\n}\n\n.success .image {\n    background-image: url('green-check.svg');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "alert_alert_10c45f72",
	"success": "alert_success_64da44b3",
	"image": "alert_image_7df1c6b0"
};
export default ___CSS_LOADER_EXPORT___;
