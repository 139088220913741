import SvgIcon, { SvgIconPropTypes } from './svg-icon';

function Paw(props) {
  return (
    <SvgIcon {...props} data-testid='icon-paw'>
      <path
        d='M19.3005 17.1368C19.2096 16.9128 19.1051 16.6948 18.9937 16.4827C18.0832 14.7523 16.6343 12.9854 15.0604 11.8272C13.7326 10.8495 11.7454 10.891 10.2419 11.3763C8.53995 11.9258 7.09889 13.1284 6.00173 14.551C5.11267 15.7053 4.41803 17.0342 4.13178 18.4676C4.05557 18.8494 4.00966 19.2519 4.12103 19.6258C4.34378 20.3736 5.1244 20.7998 5.87277 20.9991C7.23848 21.3575 8.68516 21.2246 9.96439 20.6232C10.5955 20.3272 11.1886 19.908 11.8744 19.7797C12.4817 19.6823 13.1039 19.7738 13.6584 20.0421C14.228 20.3026 14.7331 20.6972 15.3027 20.9507C15.8579 21.1958 16.4636 21.3014 17.0681 21.2585C17.7793 21.2072 18.4915 20.932 18.9761 20.4042C19.3622 19.9721 19.5916 19.4204 19.6268 18.8395C19.6493 18.2541 19.5376 17.6714 19.3005 17.1368Z'
        fill={props.fill}
      />
      <path
        d='M5.15077 8.39304C4.79066 8.08454 4.3882 7.83033 3.95592 7.63834C1.66293 6.6222 0.0059557 9.1586 0.634159 11.2639C1.03472 12.6006 2.22274 13.6178 3.55926 13.9769C4.20114 14.1495 4.9329 14.1742 5.48295 13.7924C5.7907 13.5813 6.01248 13.2646 6.20299 12.942C6.46677 12.4961 6.69148 12.0097 6.72763 11.4908C6.76866 10.9442 6.59867 10.3997 6.34954 9.91232C6.05263 9.33132 5.64528 8.81506 5.15077 8.39304Z'
        fill={props.fill}
      />
      <path
        d='M23.4849 9.6489C23.4537 9.10334 23.3452 8.55088 23.058 8.08917C22.6125 7.37294 21.7664 6.96451 20.9301 6.94774C18.8208 6.90434 17.2127 8.85276 16.985 10.8446C16.8991 11.5953 16.9997 12.3836 17.3758 13.0387C17.752 13.6937 18.4124 14.1988 19.1559 14.2758C19.8066 14.3429 20.4465 14.0913 21.0317 13.7963C21.7156 13.4481 22.3887 13.017 22.8362 12.3866C23.3726 11.6299 23.5357 10.6591 23.4937 9.7298C23.4888 9.70415 23.4869 9.67554 23.4849 9.6489Z'
        fill={props.fill}
      />
      <path
        d='M18.428 2.23403C18.1994 1.74076 17.8799 1.2909 17.4198 1.01861C16.9196 0.72265 16.3021 0.67135 15.7345 0.794668C14.0257 1.16561 12.4928 2.98381 12.357 4.74084C12.2857 5.6603 12.3805 6.55509 12.7771 7.38576C13.0019 7.85536 13.4991 8.34666 13.9495 8.6071C14.4986 8.92477 15.2001 8.83894 15.7784 8.57948C16.5903 8.21545 17.2342 7.55249 17.7862 6.84908C18.2805 6.21967 18.7299 5.51725 18.8608 4.72407C18.9703 4.05618 18.8442 3.36856 18.6264 2.72829C18.5689 2.56014 18.5027 2.39515 18.428 2.23403Z'
        fill={props.fill}
      />
      <path
        d='M11.084 4.95295C10.9766 4.16378 10.743 3.39746 10.3923 2.6839C10.1236 2.13736 9.77192 1.61054 9.259 1.28893C7.0598 -0.0922289 5.14491 2.89996 5.18203 4.82766C5.20045 5.49468 5.37356 6.14803 5.68747 6.73521C6.00138 7.32239 6.44732 7.82702 6.98946 8.20854C7.4164 8.50451 7.91564 8.72056 8.43345 8.71168C8.91315 8.70379 9.37038 8.50253 9.77974 8.24899C10.2272 7.97177 10.6444 7.61366 10.8847 7.1411C11.1368 6.64783 11.1719 6.06281 11.1387 5.50542C11.127 5.32291 11.1094 5.13744 11.084 4.95295Z'
        fill={props.fill}
      />
    </SvgIcon>
  );
}

Paw.defaultProps = {
  width: 24,
  height: 22,
  fill: '#92A6FF',
};

Paw.propTypes = {
  ...SvgIconPropTypes,
};

export default Paw;
