import range from 'lodash/range';

export function determineBlocks(page, finalPage, maxNumBlocks) {
  if (finalPage <= maxNumBlocks) {
    return range(1, finalPage + 1);
  }

  const buffer = (maxNumBlocks - 1) / 2;
  const isLeftOverflow = page - buffer <= 1;
  const isRightOverflow = page + buffer >= finalPage;

  if (!isLeftOverflow && !isRightOverflow) {
    const evenModifier = maxNumBlocks % 2 === 0 ? 1 : 0;
    return [
      1,
      '...',
      ...range(
        page - Math.floor((maxNumBlocks - 4) / 2) + evenModifier,
        page + Math.floor((maxNumBlocks - 4) / 2) + 1
      ),
      '...',
      finalPage,
    ];
  }
  if (isRightOverflow) {
    return [1, '...', ...range(finalPage - (maxNumBlocks - 3), finalPage + 1)];
  }
  return [...range(1, maxNumBlocks - 1), '...', finalPage];
}

export function formatPaginationCopy(page, resultsPerPage, totalResults, unit) {
  return `${(page - 1) * resultsPerPage + 1} - ${Math.min(
    page * resultsPerPage,
    totalResults
  )} of ${totalResults} ${unit}${totalResults > 1 ? 's' : ''}`;
}
