import SvgIcon, { SvgIconPropTypes } from './svg-icon';

function Trash({ stroke, ...restProps }) {
  const { width, height } = restProps;

  return (
    <SvgIcon {...restProps}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.50005 3.63643H1.89999C1.40294 3.63643 1 4.04344 1 4.54551C1 5.04759 1.40294 5.4546 1.89999 5.4546H2.7998V17.2728C2.7998 18.779 4.00862 20 5.49978 20H14.4997C15.9909 20 17.1997 18.779 17.1997 17.2728V5.4546H18.0998C18.5969 5.4546 18.9998 5.04759 18.9998 4.54551C18.9998 4.04344 18.5969 3.63643 18.0998 3.63643H14.5V2.72733C14.5 1.2211 13.2911 6.10352e-05 11.8 6.10352e-05H8.20002C6.70887 6.10352e-05 5.50005 1.2211 5.50005 2.72733V3.63643ZM7.30003 3.63643H12.7V2.72733C12.7 2.22525 12.297 1.81824 11.8 1.81824H8.20002C7.70297 1.81824 7.30003 2.22525 7.30003 2.72733V3.63643ZM4.59979 17.2728V5.4546H15.3997V17.2728C15.3997 17.7748 14.9967 18.1819 14.4997 18.1819H5.49978C5.00273 18.1819 4.59979 17.7748 4.59979 17.2728ZM7.2998 9.091V14.5455C7.2998 15.0476 7.70274 15.4546 8.1998 15.4546C8.69685 15.4546 9.09979 15.0476 9.09979 14.5455V9.091C9.09979 8.58892 8.69685 8.18191 8.1998 8.18191C7.70274 8.18191 7.2998 8.58892 7.2998 9.091ZM10.9004 14.5455V9.091C10.9004 8.58893 11.3033 8.18192 11.8004 8.18192C12.2974 8.18192 12.7004 8.58893 12.7004 9.091V14.5455C12.7004 15.0476 12.2974 15.4546 11.8004 15.4546C11.3033 15.4546 10.9004 15.0476 10.9004 14.5455Z'
        fill={stroke}
      />
      <mask
        id='mask0_9856_5630'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='1'
        y='0'
        width={width - 2}
        height={height}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.50005 3.63643H1.89999C1.40294 3.63643 1 4.04344 1 4.54551C1 5.04759 1.40294 5.4546 1.89999 5.4546H2.7998V17.2728C2.7998 18.779 4.00862 20 5.49978 20H14.4997C15.9909 20 17.1997 18.779 17.1997 17.2728V5.4546H18.0998C18.5969 5.4546 18.9998 5.04759 18.9998 4.54551C18.9998 4.04344 18.5969 3.63643 18.0998 3.63643H14.5V2.72733C14.5 1.2211 13.2911 6.10352e-05 11.8 6.10352e-05H8.20002C6.70887 6.10352e-05 5.50005 1.2211 5.50005 2.72733V3.63643ZM7.30003 3.63643H12.7V2.72733C12.7 2.22525 12.297 1.81824 11.8 1.81824H8.20002C7.70297 1.81824 7.30003 2.22525 7.30003 2.72733V3.63643ZM4.59979 17.2728V5.4546H15.3997V17.2728C15.3997 17.7748 14.9967 18.1819 14.4997 18.1819H5.49978C5.00273 18.1819 4.59979 17.7748 4.59979 17.2728ZM7.2998 9.091V14.5455C7.2998 15.0476 7.70274 15.4546 8.1998 15.4546C8.69685 15.4546 9.09979 15.0476 9.09979 14.5455V9.091C9.09979 8.58892 8.69685 8.18191 8.1998 8.18191C7.70274 8.18191 7.2998 8.58892 7.2998 9.091ZM10.9004 14.5455V9.091C10.9004 8.58893 11.3033 8.18192 11.8004 8.18192C12.2974 8.18192 12.7004 8.58893 12.7004 9.091V14.5455C12.7004 15.0476 12.2974 15.4546 11.8004 15.4546C11.3033 15.4546 10.9004 15.0476 10.9004 14.5455Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_9856_5630)'>
        <rect width={width} height={height} fill={stroke} />
      </g>
    </SvgIcon>
  );
}

Trash.defaultProps = {
  width: 20,
  height: 20,
  fill: 'none',
  stroke: '#373F51',
};

Trash.propTypes = {
  ...SvgIconPropTypes,
};

export default Trash;
