import { fetchAuthSession } from 'aws-amplify/auth';

import {
  BASE_API,
  BASE_PASSWORD,
  BASE_USERNAME,
  configureAmplify,
  NODE_ENV,
} from '@pumpkincare/portal-config';

import { ERROR, LOADING, SUCCESS } from '../constants';

function isResponseEmpty(response) {
  const data = response?.body?.data;
  return (data?.length || Object.keys(data).length) === 0;
}

function formatBody(body, formData) {
  //   Give priority to FormData (e.g. upload to s3)
  //   if in TEST, convert FormData to string bc whatwg-fetch cannot handle FormData blob
  //   else return stringified body
  return (
    (formData && (NODE_ENV ? JSON.stringify(formData) : formData)) ||
    (body && JSON.stringify(body)) ||
    null
  );
}

export function getDynamicHeaders(user, baseApi, isExternal) {
  // don't give non-pumpkin API calls or cloudflare API calls headers
  if (isExternal || baseApi.match(/utils/)) return {};

  const jsonContentType = { 'Content-Type': 'application/json' };

  if (user)
    return {
      ...jsonContentType,
      Authorization: `Bearer ${user.tokens.idToken.toString()}`,
    };

  // when merging to a fully shared experience, will need to remove reliance on vet env-vars as default
  const isVetApi = baseApi.match(/vet/) || baseApi.match(/8085/);
  return isVetApi
    ? {
        ...jsonContentType,
        Authorization: `Basic ${btoa(`${BASE_USERNAME}:${BASE_PASSWORD}`)}`,
      }
    : jsonContentType;
}

async function fetchWrapper(url, options = {}) {
  const {
    method = 'GET',
    headers = {},
    baseApi = BASE_API,
    body,
    formData,
    onSuccess,
    onLoading,
    onError,
    failIfEmpty,
    transformData,
    isExternal = false,
    isAuth,
  } = options;

  onLoading?.({ status: LOADING, error: null });

  if (isAuth) {
    configureAmplify();
  }
  const user = isAuth && (await fetchAuthSession());

  const internalHeaders = getDynamicHeaders(user, baseApi, isExternal);
  const path = !isExternal ? `${baseApi}${url}` : url;

  return fetch(path, {
    method,
    headers: {
      ...internalHeaders,
      ...headers,
    },
    body: formatBody(body, formData),
  })
    .then(response => {
      // I need to validate with a 404 error what happens here
      // A fetch() promise will reject with a TypeError when a network error is encountered or CORS is misconfigured on the server-side, although this usually means permission issues or similar — a 404 does not constitute a network error, for example. An accurate check for a successful fetch() would include checking that the promise resolved, then checking that the Response.ok property has a value of true.
      const isJSONResponse =
        response.headers?.get('content-type')?.indexOf('application/json') > -1;

      if (!response.ok && response.headers && !isJSONResponse) {
        throw response;
      }

      /*
         Return json only if json should be returned - generally if the content-type is application/json
         But cypress seems to append content-type even if it shouldn't, like for 204 (No Content) responses
         Fetch & Axios work as expected though for localhost/dev/prod
       */
      return isJSONResponse && response.status !== 204 ? response.json() : response;
    })
    .then(response => {
      if (
        response?.error ||
        (response?.status && String(response.status).match(/40|50/))
        /*
           sometimes we get a response with no error, but number status (e.g. from s3)
           and sometimes a string status (our BE) like "404: Resource was not found"
         */
      ) {
        throw response;
      }

      if (failIfEmpty && isResponseEmpty(response)) {
        throw Error(`${method} ${url} response is empty`);
      }

      const data = response?.body?.data || response?.body || response;
      // API endpoints return response.body.data, affiliate api just response.body, cloudflare utils just response

      const formattedData = transformData?.(data) || data;
      onSuccess?.(
        {
          status: SUCCESS,
          data: formattedData,
        },
        SUCCESS
      );
      return formattedData;
    })
    .catch(error => {
      // captureException
      onError?.({ error, status: ERROR }, ERROR);
      throw error;
    });
}

export default fetchWrapper;
