import { useMutation } from 'react-query';

import { POLICY_BASE_API } from '@pumpkincare/portal-config';

import fetchWrapper from '../fetch-wrapper';

export function useMutateAffiliateQuote() {
  return useMutation(({ partner, ...body }) =>
    fetchWrapper(`/affiliate-quotes/${partner}`, {
      method: 'POST',
      baseApi: POLICY_BASE_API,
      body,
    })
  );
}
