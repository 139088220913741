import { useMutation } from 'react-query';

import { PERSONALIZATION_BASE_API } from '@pumpkincare/portal-config';
import { fetchWrapper } from '@pumpkincare/portal-shared';

export function useMutateCreateIdentity() {
  return useMutation(({ trackingId } = {}) => {
    const url = trackingId ? `/identities/tracking-id/${trackingId}` : `/identities`;
    const method = trackingId ? 'PUT' : 'POST';

    return fetchWrapper(url, {
      method,
      baseApi: PERSONALIZATION_BASE_API,
    });
  });
}

export function useMutatePatchIdentity() {
  return useMutation(({ id, ...body }) => {
    return fetchWrapper(`/identities/${id}`, {
      method: 'PATCH',
      body,
      baseApi: PERSONALIZATION_BASE_API,
    });
  });
}
