import classNames from 'classnames';

import styles from './body2.css';

function Body2({ children, className, style }) {
  return (
    <div className={classNames(styles.body2, className)} style={style}>
      {children}
    </div>
  );
}

export default Body2;
