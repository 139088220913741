import { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useKeyboard } from '@pumpkincare/shared';

import Body1 from '../../typography/body-1';

import Typography from '../../typography.module.css';
import styles from './modal.module.css';

function formatArias(ariaLabel, ariaLabelledBy, ariaDescribedBy) {
  const attributes = {};
  if (ariaLabel) attributes['aria-label'] = ariaLabel;
  if (ariaLabelledBy) attributes['aria-labelledby'] = ariaLabelledBy;
  if (ariaDescribedBy) attributes['aria-describedby'] = ariaDescribedBy;

  return attributes;
}

function Modal({
  children,
  classes = {},
  disabled,
  stickToTheRight,
  onClose,
  title,
  subTitle,
  leftAligned,
  small,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  'aria-describedby': ariaDescribedBy,
  ...rest
}) {
  const containerClassName = classNames(styles.container, classes.container, {
    [styles.containerStickToTheRightMobile]: stickToTheRight,
    [styles.smallContainer]: small,
  });

  const contentClassName = classNames(styles.content, classes.content);
  const modalClass = classNames(styles.modal, classes.modal);
  const backDropClass = classNames(styles.backdrop, classes.backdrop);
  const modalDialogClass = classNames(styles.modalDialog, classes.modalDialog, {
    [styles.smallDialog]: small,
  });
  const modalHeaderClass = classNames(styles.modalHeader, classes.modalHeader, {
    [styles.leftAligned]: leftAligned,
  });
  const closeButtonClassName = classNames(styles.closeButton, classes.closeButton, {
    [styles.closeButtonSmall]: small,
  });

  const handleClose = useCallback(
    type => {
      if (!disabled) {
        onClose(type);
      }
    },

    [disabled, onClose]
  );

  const actionMap = useMemo(() => {
    return {
      Escape: handleClose,
    };
  }, [handleClose]);

  useKeyboard(actionMap);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return function cleanup() {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <>
      <div
        className={modalClass}
        onClick={event =>
          event.currentTarget === event.target && handleClose('backdrop')
        }
      >
        <div
          className={modalDialogClass}
          role='dialog'
          {...formatArias(ariaLabel, ariaLabelledBy, ariaDescribedBy)}
        >
          <div className={styles.closeButtonContainer}>
            <button
              id={'close-modal'}
              className={closeButtonClassName}
              onClick={() => handleClose('icon')}
              aria-label='closeModal'
            />
          </div>

          <div
            className={containerClassName}
            onClick={e => e.stopPropagation()}
            {...rest}
          >
            {title ? (
              <div className={modalHeaderClass}>
                <span className={classNames(Typography.h3, styles.modalTitle)}>
                  {title}
                </span>

                <Body1 className={styles.modalSubTitle}>{subTitle}</Body1>
              </div>
            ) : null}

            <div className={contentClassName}>{children}</div>
          </div>
        </div>
      </div>

      <div
        className={backDropClass}
        data-testid={`backdrop-div`}
        onClick={() => handleClose('backdrop')}
      />
    </>
  );
}

Modal.defaultProps = {
  classes: {},
  disabled: false,
  stickToTheRight: false,
  leftAligned: false,
  title: '',
  subTitle: '',
  small: false,
};

Modal.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    backdrop: PropTypes.string,
    modal: PropTypes.string,
    modalHeader: PropTypes.string,
    modalBody: PropTypes.string,
    modalDialog: PropTypes.string,
  }),
  leftAligned: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,

  disabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  stickToTheRight: PropTypes.bool,
  small: PropTypes.bool,

  // spread via ...rest
  'aria-labelledby': PropTypes.string,
  'aria-describedby': PropTypes.string,
  'aria-label': PropTypes.string,
};

export default Modal;
