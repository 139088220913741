// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".radio-group_inputs_6c3f92fa {\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column;\n  gap: 16px 0;\n}\n\n.radio-group_row_d4d91e5e {\n  flex-direction: row;\n}\n\n.radio-group_inputs_6c3f92fa.radio-group_row_d4d91e5e {\n  gap: 0 16px;\n}\n\n.radio-group_error_2f384338 {\n  color: var(--rowlfRed);\n  flex: 100%;\n  margin-top: 2px;\n}", "",{"version":3,"sources":["webpack://../../libs/shared/src/ui/inputs/radio-group/radio-group.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,UAAU;EACV,eAAe;AACjB","sourcesContent":[".inputs {\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column;\n  gap: 16px 0;\n}\n\n.row {\n  flex-direction: row;\n}\n\n.inputs.row {\n  gap: 0 16px;\n}\n\n.error {\n  color: var(--rowlfRed);\n  flex: 100%;\n  margin-top: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputs": "radio-group_inputs_6c3f92fa",
	"row": "radio-group_row_d4d91e5e",
	"error": "radio-group_error_2f384338"
};
export default ___CSS_LOADER_EXPORT___;
