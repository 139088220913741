// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".svg-icon_root_d15957ac {\n  display: inline-block;\n  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n  user-select: none;\n  flex-shrink: 0;\n}\n\n.svg-icon_root_d15957ac:not([fill]) {\n  fill: currentColor;\n}", "",{"version":3,"sources":["webpack://../../libs/shared/src/ui/icons/svg-icon/svg-icon.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,uDAAuD;EACvD,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".root {\n  display: inline-block;\n  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n  user-select: none;\n  flex-shrink: 0;\n}\n\n.root:not([fill]) {\n  fill: currentColor;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "svg-icon_root_d15957ac"
};
export default ___CSS_LOADER_EXPORT___;
