import Store from 'store';

const GUIDE_KEY = '@pumpkincares/guided-tooltip';

export function assessIsGuidedAndFirstVisit(guideKey) {
  return !!guideKey && !Store.get(GUIDE_KEY)?.[guideKey];
}

export function setWasGuided(guideKey) {
  Store.set(GUIDE_KEY, {
    ...Store.get(GUIDE_KEY),
    [guideKey]: true,
  });
}

export function clearGuide() {
  Store.remove(GUIDE_KEY);
}
