// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../breakpoints.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sub-text1_subText1_d14d61c1 {\n  font-weight: 600;\n  font-size: 12px;\n  line-height: 16px;\n  opacity: 0.7;\n  color: var(--scoutNavy);\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + " {\n  .sub-text1_subText1_d14d61c1 {\n    font-size: 14px;\n    line-height: 18px;\n  }\n}", "",{"version":3,"sources":["webpack://../../libs/portal-ui/src/sub-text1/sub-text1.css"],"names":[],"mappings":"AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":["@value b-desktop from '../breakpoints.css';\n\n.subText1 {\n  font-weight: 600;\n  font-size: 12px;\n  line-height: 16px;\n  opacity: 0.7;\n  color: var(--scoutNavy);\n}\n\n@media b-desktop {\n  .subText1 {\n    font-size: 14px;\n    line-height: 18px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"b-desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + "",
	"subText1": "sub-text1_subText1_d14d61c1"
};
export default ___CSS_LOADER_EXPORT___;
