// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../breakpoints.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".body2_body2_c13b0162 {\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 20px;\n  color: var(--scoutNavy);\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + " {\n  .body2_body2_c13b0162 {\n    font-size: 16px;\n    line-height: 24px;\n  }\n}", "",{"version":3,"sources":["webpack://../../libs/portal-ui/src/body2/body2.css"],"names":[],"mappings":"AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":["@value b-desktop from '../breakpoints.css';\n\n.body2 {\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 20px;\n  color: var(--scoutNavy);\n}\n\n@media b-desktop {\n  .body2 {\n    font-size: 16px;\n    line-height: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"b-desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + "",
	"body2": "body2_body2_c13b0162"
};
export default ___CSS_LOADER_EXPORT___;
