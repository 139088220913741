import { useQuery } from 'react-query';

import { POLICY_BASE_API } from '@pumpkincare/portal-config';
import { fetchWrapper, validateEmail } from '@pumpkincare/portal-shared';

import { transformQuote } from './utils/quote-utils';

export const QUOTE_QUERY = 'quote';
const QUOTE_QUERY_EMAIL = 'quote/email';

export const getQuotePets = quote => quote.pets;
export const getQuotePet = quote => petId =>
  getQuotePets(quote).find(pet => pet.id === petId);
export const getQuoteFirstPetId = quote => quote.pets[0]?.id;
export const getQuotePetName = quote => petId => getQuotePet(quote)(petId)?.petName;
export const getQuotePetBreedCode = quote => petId =>
  getQuotePet(quote)(petId)?.petBreedCode;
export const getQuotePetAge = quote => petId => getQuotePet(quote)(petId)?.petAge;
export const getQuotePolicyZipCode = quote => quote.policyZipcode;
export const getQuoteVetId = quote => quote.vet.id;

export function useQuote({ quoteId } = {}, options = {}) {
  return useQuery(
    [QUOTE_QUERY, quoteId],

    () =>
      fetchWrapper(`/quotes-v3/${quoteId}`, {
        baseApi: POLICY_BASE_API,
        transformData: transformQuote,
      }),

    {
      enabled: !!quoteId,

      placeholderData: {
        email: '',
        firstName: '',
        id: quoteId,
        isRegistered: false,
        lastName: '',
        partner: '',
        pets: [],
        policyZipcode: '',
        policyState: '',
        status: '',
        trackingId: '',
        transactionFee: 0,
        vet: {},
      },

      retry: 3,
      ...options,
    }
  );
}

export function useQuoteEmail(email) {
  return useQuery(
    [QUOTE_QUERY_EMAIL, email],
    () =>
      fetchWrapper('/users/check_email', {
        method: 'POST',
        baseApi: POLICY_BASE_API,
        body: { email },
      }),

    {
      enabled: !!validateEmail(email),
      placeholderData: {},
    }
  );
}
