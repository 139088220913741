import { createContext, useContext } from 'react';

import { useToggleState } from '@pumpkincare/portal-shared';

const NavContext = createContext();

export function NavBarProvider({ children }) {
  return (
    <NavContext.Provider value={useToggleState(false)}>
      {children}
    </NavContext.Provider>
  );
}

export function useNavContext() {
  const [isNavBarOpen, toggleNavBar] = useContext(NavContext);

  return { isNavBarOpen, toggleNavBar };
}
