import PropTypes from 'prop-types';

import SvgIcon, { SvgIconPropTypes } from './svg-icon';

function Heart(props) {
  const { uneven, ...rest } = props;
  return (
    <SvgIcon {...rest} data-testid='icon-heart'>
      {uneven ? (
        <path
          d='M11.022 18.7853C7.17992 15.8676 0.731644 12.7838 0.0656842 7.59949C-0.14667 6.26025 0.160172 4.89063 0.923805 3.76873C2.06362 2.24919 4.18317 1.69374 6.04658 2.02574C7.90998 2.45437 9.63052 3.35618 11.0412 4.64349C12.2504 3.36285 13.6395 2.26382 15.1651 1.38089C15.937 0.943568 16.7955 0.679754 17.6804 0.608066C18.5653 0.536379 19.4553 0.658615 20.2879 0.965953C20.8859 1.22694 21.4204 1.61352 21.8547 2.09947C22.2891 2.58542 22.613 3.15921 22.8044 3.78151C23.1319 5.00274 23.0464 6.29758 22.5611 7.46544C22.0769 8.61553 21.4299 9.69054 20.64 10.6577C18.3668 13.6138 16.6699 15.9506 13.9548 18.5108C13.4234 19.0152 12.751 19.5514 12.0338 19.3791C11.7439 19.2811 11.4762 19.1271 11.2462 18.9258L11.022 18.7853Z'
          fill={props.fill}
        />
      ) : (
        <path
          d='M20.1599 2.89518C19.6665 1.80983 18.8265 0.889815 17.7278 0.380475C16.5225 -0.176865 15.0691 -0.0248635 13.8745 0.556478C12.6798 1.13515 11.7091 2.1005 10.8877 3.14318C10.2611 2.40984 9.62373 1.66583 8.82105 1.12715C8.25304 0.745813 7.5917 0.47381 6.90369 0.428476C5.91168 0.361808 4.82899 0.516477 3.92498 0.889815C3.02097 1.26315 2.11696 2.00716 1.55962 2.81784C0.13293 4.89254 0.514269 7.58057 2.04762 9.57526C4.30632 12.5166 7.55703 14.57 10.9917 15.9673C11.9091 15.1673 12.9731 14.554 13.9678 13.8527C16.8158 11.842 20.7305 9.03392 20.6692 5.12987C20.6585 4.36186 20.4825 3.59652 20.1625 2.89518H20.1599Z'
          fill={props.fill}
        />
      )}
    </SvgIcon>
  );
}

Heart.defaultProps = {
  width: 23,
  height: 20,
  fill: '#92A6FF',
  uneven: false,
};

Heart.propTypes = {
  ...SvgIconPropTypes,
  uneven: PropTypes.bool,
};

export default Heart;
