import classNames from 'classnames';

import styles from './toggle-button.css';

function ToggleButton({
  label = '',
  icon,
  selected,
  value,
  clickHandler = () => {},
  direction,
  classes = {},
  variant = 'default',
}) {
  const isVertical = direction === 'v';
  const componentClassName = classNames(
    styles.toggleButton,
    {
      [styles.toggleButtonVertical]: isVertical,
      [styles.toggleButtonSelected]: selected,
      [styles.portal]: variant === 'portal',
    },
    classes.container
  );
  const imgClass = classNames(styles.img, classes.img, {
    [styles.imageVertical]: isVertical,
    [styles.imageHorizontal]: !isVertical,
  });

  return (
    <button
      className={componentClassName}
      onClick={() => clickHandler({ value })}
      type='button'
    >
      {icon ? (
        <img className={imgClass} src={icon} alt={label} role='presentation' />
      ) : null}
      <span>{label}</span>
    </button>
  );
}

export default ToggleButton;
