import TextAreaAutosize from 'react-textarea-autosize';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import LegalBody from '../../typography/legal-body';

import Typography from '../../typography.module.css';
import styles from './text-area.css';

function TextArea({
  classes,
  label,
  error: { errorMessage = '', hasError },
  showCount,
  ...restTextAreaProps
}) {
  const { id, placeholder, required, readOnly, maxLength, value } =
    restTextAreaProps;

  const labelClassName = classNames(
    styles.label,
    classes.label,
    Typography.legalBody,
    {
      [styles.hidden]: !(label || placeholder),
      [styles.readOnly]: readOnly,
    }
  );
  const textAreaClassName = classNames(
    styles.textArea,
    classes.root,
    Typography.body2,
    {
      [styles.error]: errorMessage || hasError,
      [styles.readOnly]: readOnly,
    }
  );

  return (
    <div className={classNames(styles.root, classes.root)}>
      <label htmlFor={id} className={labelClassName}>
        {label || placeholder}
        {required ? <span className={styles.asterisk}>*</span> : null}
      </label>

      <TextAreaAutosize
        id={id}
        className={textAreaClassName}
        {...restTextAreaProps}
      />

      {errorMessage ? (
        <LegalBody className={styles.errorMessage}>{errorMessage}</LegalBody>
      ) : null}

      {!errorMessage && maxLength && showCount ? (
        <LegalBody
          className={classNames(styles.lengthTracker, {
            [styles.valid]: value.length && value.length < maxLength,
            [styles.filled]: value.length === maxLength,
          })}
        >
          {value.length}/{maxLength} characters
        </LegalBody>
      ) : null}
    </div>
  );
}

export default TextArea;

TextArea.defaultProps = {
  rows: 1,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  error: {},
  classes: {},
};

TextArea.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
  }),
  label: PropTypes.any,
  /** hasError will do just a red border, errorMessage will add a red message underneath too */
  error: PropTypes.shape({
    errorMessage: PropTypes.any,
    hasError: PropTypes.bool,
  }),
  /** render a character count if there is a maxLength and no error */
  showCount: PropTypes.bool,

  // special props for react-textarea-autosize
  // https://github.com/Andarist/react-textarea-autosize?tab=readme-ov-file#props
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  onHeightChange: PropTypes.func,
  cacheMeasurements: PropTypes.bool,

  // ...restTextAreaProps get spread directly into <textarea>
  id: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  /** default minimum height for the textarea */
  rows: PropTypes.number,
  'data-testid': PropTypes.string,
};
