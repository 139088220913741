// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select_wrapper_43ca9122 {\n    width: 100%;\n    margin: auto;\n    cursor: pointer !important;\n}\n\n.select_label_8d2424bc {\n    visibility: hidden;\n    color: var(--bluesCluesBlue);\n}\n\n.select_visible_c0a60f17 {\n    visibility: visible;\n}\n\ndiv.select_errorMessage_240070c9 {\n    color: var(--crookshanksRed);\n    font-size: 12px;\n    margin-top: 8px;\n    min-height: 20px;\n    font-weight: 400;\n    text-align: left;\n}", "",{"version":3,"sources":["webpack://../../libs/portal-ui/src/select/select.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;IAClB,4BAA4B;AAChC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,4BAA4B;IAC5B,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".wrapper {\n    width: 100%;\n    margin: auto;\n    cursor: pointer !important;\n}\n\n.label {\n    visibility: hidden;\n    color: var(--bluesCluesBlue);\n}\n\n.visible {\n    visibility: visible;\n}\n\ndiv.errorMessage {\n    color: var(--crookshanksRed);\n    font-size: 12px;\n    margin-top: 8px;\n    min-height: 20px;\n    font-weight: 400;\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "select_wrapper_43ca9122",
	"label": "select_label_8d2424bc",
	"visible": "select_visible_c0a60f17",
	"errorMessage": "select_errorMessage_240070c9"
};
export default ___CSS_LOADER_EXPORT___;
