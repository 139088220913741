import classNames from 'classnames';

import styles from './body-subhead.css';

function BodySubhead({ children, className, style }) {
  return (
    <div className={classNames(styles.bodySubhead, className)} style={style}>
      {children}
    </div>
  );
}

export default BodySubhead;
