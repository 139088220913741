import { Children, cloneElement } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { convertIfBool } from '../../../utils/string-utils';
import LegalBody from '../../typography/legal-body';

import styles from './radio-group.module.css';

function RadioGroup(props) {
  const {
    classes = {},
    children,
    id,
    onChange,
    style = {},
    error: { hasError, errorMessage = '' } = {},
    row,
    value,
    ...rest
  } = props;
  const allValues = Children.map(children, child => child.props.value);
  const hasCheckedChild = allValues.some(childValue => childValue === value);

  return (
    <div id={id} className={classes.root} style={style}>
      <div
        className={classNames(styles.inputs, classes.inputs, { [styles.row]: row })}
        role='radiogroup'
      >
        {Children.map(children, (child, index) => {
          const isChecked = child.props.value === value;

          return cloneElement(child, {
            ...rest,
            checked: isChecked,
            'aria-checked': isChecked,
            error: { hasError: hasError || !!errorMessage },
            onChange: function (e) {
              onChange(convertIfBool(e.target.value));
            },
            tabIndex: calcTabIndex(
              hasCheckedChild,
              isChecked,
              index,
              children.length
            ),
          });
        })}
      </div>

      {errorMessage ? (
        <LegalBody className={styles.error} data-testid={`${id}-error`}>
          {errorMessage}
        </LegalBody>
      ) : null}
    </div>
  );
}

RadioGroup.propTypes = {
  classes: PropTypes.object,
  /** this is passed into the child inputs */
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
  error: PropTypes.shape({
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
  }),
  /** render the children in a row instead of column */
  row: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

export default RadioGroup;

// for ADA
export function calcTabIndex(hasCheckedChild, isChecked, index, childrenLength) {
  // if a button is selected, that button has tabIndex 0 and all others have -1
  if (hasCheckedChild && isChecked) {
    return isChecked ? 0 : -1;
  }

  // if no button is selected, the first and last buttons have tabIndex 0 and the others have -1
  return index === 0 || index === childrenLength - 1 ? 0 : -1;
}
