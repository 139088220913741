import classNames from 'classnames';

import styles from './legal-body.css';

function LegalBody({ children, className, style }) {
  return (
    <div className={classNames(styles.legalBody, className)} style={style}>
      {children}
    </div>
  );
}

export default LegalBody;
