import { Amplify } from 'aws-amplify';

import { AWS_AMPLIFY_SETTINGS } from './env';

export function configureAmplify() {
  Amplify.configure({
    Auth: {
      Cognito: {
        ...AWS_AMPLIFY_SETTINGS,
        region: 'us-east-1',
        identityPoolRegion: 'us-east-1',
        mandatorySignIn: true,

        allowGuestAccess: true,
        // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
        // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
        signUpVerificationMethod: 'link', // 'code' | 'link'
        loginWith: {
          // OPTIONAL - Hosted UI configuration
        },
      },
    },
  });
}
