import { useEffect } from 'react';
import classNames from 'classnames';

import styles from './alert.css';

export default function Alert({ classes = {}, type, message, timer, onTimer }) {
  useEffect(() => {
    if (timer && onTimer) {
      setTimeout(onTimer, timer);
    }
  }, [timer, onTimer]);

  return (
    <div
      className={classNames(styles.alert, styles[type], classes.alert)}
      data-testid='alert'
    >
      <div role='presentation' className={styles.icon} />
      <span>{message}</span>
    </div>
  );
}
