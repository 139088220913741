// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".file-input_container_b658bcc3 {\n    position: relative;\n    padding: 0;\n}\n\n/* to hide the native image input on top of our designed button */\n.file-input_input_e8d653bc {\n    opacity: 0;\n    position: absolute;\n    left: 0;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    overflow: hidden;\n    width: 100%;\n    cursor: pointer;\n}\n\n.file-input_disabled_53cad9de {\n    cursor: not-allowed;\n}", "",{"version":3,"sources":["webpack://../../libs/portal-ui/src/file-input/file-input.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA,iEAAiE;AACjE;IACI,UAAU;IACV,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,QAAQ;IACR,SAAS;IACT,gBAAgB;IAChB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".container {\n    position: relative;\n    padding: 0;\n}\n\n/* to hide the native image input on top of our designed button */\n.input {\n    opacity: 0;\n    position: absolute;\n    left: 0;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    overflow: hidden;\n    width: 100%;\n    cursor: pointer;\n}\n\n.disabled {\n    cursor: not-allowed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "file-input_container_b658bcc3",
	"input": "file-input_input_e8d653bc",
	"disabled": "file-input_disabled_53cad9de"
};
export default ___CSS_LOADER_EXPORT___;
