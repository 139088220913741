import { useQuery } from 'react-query';

import { fetchWrapper } from '@pumpkincare/portal-shared';

const VISIT_PATIENT_QUERY = 'visit/patient';
const VISIT_PHOTO_QUERY = 'visit/photo';

export function useVisitPatient({ vetId, visitId }, options = {}) {
  return useQuery(
    [VISIT_PATIENT_QUERY, vetId, visitId],
    () =>
      fetchWrapper(`/vets/${vetId}/visits/${visitId}`, {
        failIfEmpty: true,
      }),

    {
      enabled: !!vetId && !!visitId,
      placeholderData: {},
      staleTime: 1000 * 60 * 10,
      ...options,
    }
  );
}

export function useVisitPhoto({ vetId, visitId }, options = {}) {
  return useQuery(
    [VISIT_PHOTO_QUERY, vetId, visitId],
    () =>
      fetchWrapper(`/vets/${vetId}/visits/${visitId}/photos`, {
        failIfEmpty: true,
        transformData: data => data[0],
      }),

    {
      enabled: !!vetId && !!visitId,
      placeholderData: {},
      staleTime: 1000 * 60 * 10,
      ...options,
    }
  );
}
