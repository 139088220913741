import classNames from 'classnames';

import styles from './checkbox.css';

export default function Checkbox({
  id,
  label,
  checked = false,
  onChange,
  required,
  isErrorVisible,
  classes = {},
}) {
  return (
    <div className={classNames(styles.container, classes.container)}>
      <input
        type='checkbox'
        id={id}
        required={required}
        className={classNames(styles.input, {
          [styles.error]: isErrorVisible && !checked,
        })}
        checked={checked}
        onChange={onChange}
      />
      <span
        className={classNames(styles.box, classes.box, {
          [styles.error]: isErrorVisible && !checked,
        })}
        onClick={onChange}
      />
      <label
        htmlFor={id}
        className={classNames(styles.label, classes.label, {
          [styles.error]: isErrorVisible && !checked,
        })}
      >
        {label}
        {required ? <span className={styles.asterisk}>*</span> : null}
      </label>
    </div>
  );
}
