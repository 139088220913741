import classNames from 'classnames';

import styles from './a.css';

function A({
  href,
  children,
  className,
  style,
  target,
  download = false,
  onClick = () => {},
}) {
  return (
    <a
      className={classNames(styles.link, className)}
      href={href}
      style={style}
      target={target}
      download={download}
      onClick={onClick}
    >
      {children}
    </a>
  );
}

export default A;
