import { useEffect } from 'react';

import { captureException } from '../utils/error-tracking';

export default function useKeyboard(actionMap) {
  return useEffect(() => {
    function handleKeyDown({ key }) {
      const action = actionMap[key];

      if (action && typeof action !== 'function') {
        captureException(`Key ${key} does not point to a function`);
      } else if (action) {
        actionMap[key] && actionMap[key]();
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [actionMap]);
}
