import { useEffect, useRef, useState } from 'react';

import Processing from '../processing';

export default function InfiniteScroll({
  children,
  onScrollToBottom,
  isLoading,
  isDone,
}) {
  const [intersected, setIntersected] = useState(null);

  const options = {
    rootMargin: '0px',
    threshold: 1,
  };
  const observer = useRef(new IntersectionObserver(setIntersected, options));
  const bottomSection = useRef(null);

  useEffect(() => {
    if (intersected && intersected[0].isIntersecting && !isDone && !isLoading) {
      onScrollToBottom();
    }
  }, [intersected, isDone, isLoading, onScrollToBottom]);

  useEffect(() => {
    const { current: currentObserver } = observer;

    if (bottomSection?.current) {
      currentObserver.observe(bottomSection.current);
    }

    return () => {
      currentObserver.disconnect();
    };
  }, [isLoading, isDone]);

  return (
    <>
      {children}
      {!isDone && !isLoading ? (
        <section
          ref={bottomSection}
          id='infinite-scroll-bottom'
          data-testid='infinite-scroll-bottom'
        />
      ) : null}
      {isLoading ? <Processing /> : null}
    </>
  );
}
