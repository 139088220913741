import PropTypes from 'prop-types';

import CollapsiblePanel from '../../organizational/collapsible-panel';
import Body1 from '../../typography/body-1';
import Body2 from '../../typography/body-2';

import styles from './faq-set.css';

function FAQSet({ questionSet }) {
  return (
    <>
      {questionSet.map((questionObj, i) => {
        return (
          <CollapsiblePanel
            rootProps={{
              'data-testid': `question-container-${i}`,
              itemProp: 'mainEntity',
              itemScope: ' ', // Empty string with space so React will pass it down
              itemType: 'https://schema.org/Question',
            }}
            key={i}
            title={
              <Body1 itemProp='name' className={styles.faqQuestion}>
                {questionObj.question}
              </Body1>
            }
            classes={{
              collapseContainer: styles.collapseContainer,
              collapseButton: styles.collapseButton,
              title: styles.collapseTitle,
              contentContainer: styles.contentContainer,
            }}
          >
            <div
              itemScope
              itemProp='acceptedAnswer'
              itemType='https://schema.org/Answer'
              data-testid='answer-wrapper'
            >
              <Body2 itemProp='text'>{questionObj.answer}</Body2>
            </div>
          </CollapsiblePanel>
        );
      })}
    </>
  );
}

FAQSet.propTypes = {
  questionSet: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.node.isRequired,
      answer: PropTypes.node.isRequired,
    })
  ).isRequired,
};

export default FAQSet;
