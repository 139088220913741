// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".password-requirements_green_1727bce4,\n.password-requirements_red_5a0cd9b6 {\n    display: flex;\n    align-items: center;\n    margin-bottom: 8px;\n}\n\n.password-requirements_green_1727bce4 span,\n.password-requirements_green_1727bce4 div {\n    color: var(--primaryGreen);\n}\n\n.password-requirements_red_5a0cd9b6 span,\n.password-requirements_red_5a0cd9b6 div {\n    color: var(--redAlert);\n}\n\n.password-requirements_copy_36f6138f {\n    font-size: 12px;\n    line-height: 16px;\n}\n\n.password-requirements_icon_c68d79a6 {\n    width: 16px;\n    margin-right: 8px;\n}", "",{"version":3,"sources":["webpack://../../libs/portal-ui/src/password-requirements/password-requirements.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;;IAEI,0BAA0B;AAC9B;;AAEA;;IAEI,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB","sourcesContent":[".green,\n.red {\n    display: flex;\n    align-items: center;\n    margin-bottom: 8px;\n}\n\n.green span,\n.green div {\n    color: var(--primaryGreen);\n}\n\n.red span,\n.red div {\n    color: var(--redAlert);\n}\n\n.copy {\n    font-size: 12px;\n    line-height: 16px;\n}\n\n.icon {\n    width: 16px;\n    margin-right: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"green": "password-requirements_green_1727bce4",
	"red": "password-requirements_red_5a0cd9b6",
	"copy": "password-requirements_copy_36f6138f",
	"icon": "password-requirements_icon_c68d79a6"
};
export default ___CSS_LOADER_EXPORT___;
