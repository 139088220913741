import { useRef, useState } from 'react';
import classNames from 'classnames';

import { useTargetState, validateEmail } from '@pumpkincare/portal-shared';

import Body1 from '../body1';
import LegalBody from '../legal-body';
import SubText1 from '../sub-text1';

import styles from './multi-email-field.css';

import closeIcon from './close.svg';

export default function MultiEmailField({
  onChange,
  header = 'Send To',
  placeholder = '',
  hasSubmittedOnce,
  classes = {},
}) {
  // may be tempting to move emails / error handling to parent component - in this case better to have parent ref and
  // child state so that the parent component doesnt rerender while this component is updating state and trigger
  // a page scroll on iOS
  const [emails, setEmails] = useState([]);
  const [value, setValue, isValidValue] = useTargetState('', validateEmail);
  const [error, setError] = useState('');
  const ref = useRef(null);

  function handleDelete(e, removeEmail) {
    e.stopPropagation();

    const newEmails = emails.filter(({ email }) => email !== removeEmail);
    setEmails(newEmails);
    onChange(newEmails);
  }

  function handleAdd() {
    if (value) {
      if (emails.some(({ email }) => email === value)) {
        setError(`This email has already been added.`);
        return;
      }

      const newEmails = [...emails, { email: value, valid: isValidValue }];
      setEmails(newEmails);
      onChange(newEmails);
      setError('');
      setValue({ target: { value: '' } });
    }
  }

  function handleKeyDown(e) {
    if (['Enter', ',', ' '].includes(e.key)) {
      e.preventDefault();
      handleAdd();
    }
  }

  function handlePaste(e) {
    e.preventDefault();

    const emailsToAdd = [];
    const pastedEmails = e.clipboardData.getData('text').split(/\s*[\s,]\s*/);
    pastedEmails.forEach(pastedEmail => {
      if (
        pastedEmail &&
        !emails.some(({ email }) => email === pastedEmail) &&
        !emailsToAdd.some(({ email }) => email === pastedEmail)
      ) {
        emailsToAdd.push({
          email: pastedEmail,
          valid: validateEmail(pastedEmail),
        });
      }
    });

    const newEmails = [...emails, ...emailsToAdd];
    setEmails(newEmails);
    onChange(newEmails);
  }

  const errorMessage = getErrorMessage(error, emails, hasSubmittedOnce);

  return (
    <div className={classNames(styles.container, classes.container)}>
      <Body1
        className={classNames(styles.label, {
          [styles.error]: errorMessage,
        })}
      >
        {header}
      </Body1>
      <SubText1 className={styles.note}>
        Note: You can type or copy and paste multiple emails into this text box. When
        copying and pasting, they should be separated by a comma, space, or new line.
      </SubText1>

      <div
        data-testid='multi-email-container'
        className={classNames(styles.multiEmailContainer, {
          [styles.error]: errorMessage,
        })}
        onClick={() => ref.current.focus()}
      >
        {emails.map(({ email, valid }) => {
          return (
            <div
              className={classNames(styles.tag, { [styles.invalid]: !valid })}
              key={email}
              onClick={e => e.stopPropagation()}
            >
              {email}
              <button
                type='button'
                aria-label='remove'
                className={styles.remove}
                onClick={e => handleDelete(e, email)}
              >
                <img src={closeIcon} alt='' />
              </button>
            </div>
          );
        })}
        <input
          ref={ref}
          id='input'
          className={styles.input}
          placeholder={placeholder}
          value={value}
          onChange={setValue}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          onBlur={handleAdd}
          size={value.length + 1}
        />
      </div>

      <LegalBody className={styles.errorMessage}>{errorMessage}</LegalBody>
    </div>
  );
}

function getErrorMessage(error, emails, hasSubmittedOnce) {
  if (error) {
    return error;
  }
  if (!emails.length && hasSubmittedOnce) {
    return 'Please enter an email';
  }
  if (emails.some(({ valid }) => !valid)) {
    return 'Please make sure all email addresses are valid';
  }

  return '';
}
