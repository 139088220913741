import { useState } from 'react';
import PropTypes from 'prop-types';

import Hamburger from '../../icons/hamburger';
import Link from '../../navigation/route-link';
import SlideOutPanel from '../slide-out-panel';

import styles from './side-nav.css';

function SideNav({ links, partnerSrc }) {
  const [isSlideOutOpen, setSlideOutOpen] = useState(false);

  function renderContent() {
    return (
      <>
        <div className={styles.logos}>
          <img alt='' src='/assets/images/Pumpkin_Logo_White.svg' />

          {partnerSrc ? (
            <img src={partnerSrc} className={styles.partner} alt='x' />
          ) : null}
        </div>

        <div className={styles.links}>
          {links.map(link => (
            <Link key={link.title} to={link.href}>
              <span>{link.title}</span>
            </Link>
          ))}
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.mobileNav}>
        <button onClick={() => setSlideOutOpen(true)} aria-label='Open Panel'>
          <Hamburger />
        </button>
      </div>

      <div className={styles.desktopNav}>{renderContent()}</div>

      <SlideOutPanel
        classes={{ container: styles.slideOut }}
        show={isSlideOutOpen}
        hasClose
        direction='left'
        onClose={() => setSlideOutOpen(false)}
      >
        {renderContent()}
      </SlideOutPanel>
    </>
  );
}

SideNav.defaultProps = {
  partnerSrc: '',
};

SideNav.propTypes = {
  /** What links do you want to render */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,

  /** An option for rendering an extra logo, primarily a partner */
  partnerSrc: PropTypes.string,
};

export default SideNav;
