import { useRef } from 'react';
import classNames from 'classnames';

import LegalBody from '../legal-body';

import styles from './text-area.css';

function TextArea({
  propRef,
  classes = {},
  id,
  value,
  placeholder,
  label,
  rows,
  errorMessage = '',
  hasError, // errorMessage if you want to render an error, hasError if you just want the style
  required,
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
  readOnly,
  isLabelHidden = false,
}) {
  const ref = propRef || useRef(null);

  const labelClassName = classNames(styles.label, classes.label, {
    [styles.error]: errorMessage || hasError,
    [styles.placeholder]: !value,
    [styles.hidden]: value && isLabelHidden,
    [styles.readOnly]: readOnly,
  });
  const textAreaClassName = classNames(styles.textArea, classes.textArea, {
    [styles.error]: errorMessage || hasError,
    [styles.readOnly]: readOnly,
  });

  return (
    <div className={classNames(styles.container, classes.container)}>
      <label htmlFor={id} className={labelClassName}>
        {label || placeholder}
        {required ? <span className={styles.asterisk}>*</span> : null}
      </label>

      <div className={styles.textAreaContainer}>
        <textarea
          className={textAreaClassName}
          id={id}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          label={placeholder}
          value={value}
          required={required}
          readOnly={readOnly}
          rows={rows}
          data-testid='text-area'
        />
      </div>

      {errorMessage ? (
        <LegalBody className={styles.errorMessage}>{errorMessage}</LegalBody>
      ) : null}
    </div>
  );
}

export default TextArea;
