const USER_AGENTS = {
  desktop: null,
  touchDevice: null,
};

function initializeUserAgent() {
  const isTouchDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  USER_AGENTS.desktop = !isTouchDevice;
  USER_AGENTS.touchDevice = isTouchDevice;
}

export { USER_AGENTS, initializeUserAgent };
