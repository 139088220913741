import classNames from 'classnames';

import styles from './sub-text1.css';

function SubText1({ children, className, style }) {
  return (
    <div className={classNames(styles.subText1, className)} style={style}>
      {children}
    </div>
  );
}

export default SubText1;
