import classNames from 'classnames';

import Button from '../button';
import Processing from '../processing';

import styles from '../button/button.css';

function ButtonSecondary({
  children,
  className,
  isLoading = false,
  disabled,
  styledDisabled,
  onClick = () => {},
  type = 'button',
  style,
}) {
  return (
    <Button
      type={type}
      className={classNames(styles.secondary, className)}
      styledDisabled={styledDisabled}
      disabled={disabled || isLoading}
      onClick={onClick}
      style={style}
    >
      {isLoading ? <Processing /> : children}
    </Button>
  );
}

export default ButtonSecondary;
