// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pagination_container_8f30f619 {\n    margin: 10px 0 20px;\n}\n\n.pagination_buttons_21c46c3a {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 10px;\n}\n\n.pagination_leftArrow_6fc5ba60 {\n    transform: rotate(180deg);\n}\n\n.pagination_notAllowed_5e5b466f {\n    opacity: 0.3;\n}\n\n.pagination_page_1051633e {\n    padding: 0;\n    min-height: 0;\n    width: 24px;\n    height: 24px;\n    margin: 0 4px;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.pagination_selected_62cfceb8 {\n    background: var(--puppyPurple);\n    color: white;\n    border-radius: 50%;\n}", "",{"version":3,"sources":["webpack://../../libs/portal-ui/src/pagination/pagination.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,WAAW;IACX,YAAY;IACZ,aAAa;;IAEb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;IAC9B,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".container {\n    margin: 10px 0 20px;\n}\n\n.buttons {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 10px;\n}\n\n.leftArrow {\n    transform: rotate(180deg);\n}\n\n.notAllowed {\n    opacity: 0.3;\n}\n\n.page {\n    padding: 0;\n    min-height: 0;\n    width: 24px;\n    height: 24px;\n    margin: 0 4px;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.selected {\n    background: var(--puppyPurple);\n    color: white;\n    border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "pagination_container_8f30f619",
	"buttons": "pagination_buttons_21c46c3a",
	"leftArrow": "pagination_leftArrow_6fc5ba60",
	"notAllowed": "pagination_notAllowed_5e5b466f",
	"page": "pagination_page_1051633e",
	"selected": "pagination_selected_62cfceb8"
};
export default ___CSS_LOADER_EXPORT___;
