export function transformPet(pet) {
  const genders = {
    M: 'male',
    F: 'female',
  };

  return {
    annual_limit: pet.annual_limit,
    copay: pet.copay,
    deductible: pet.deductible,
    hasPrevent: pet.has_prevent,
    id: pet.id,
    partner: pet.partner,
    petAge: pet.age,
    petBreedCode: pet.breed_code,
    petBreedName: pet.breed_name,
    petBreedSpecies: pet.species.toLowerCase(),
    petBreedType: pet.breed_type,
    petDOB: pet.birth_date,
    petGender: genders[pet.gender],
    petName: pet.name,
    plan: pet.plan,

    preventativeSelected: pet.has_prevent, // deprecated
  };
}

export function transformQuote(quote) {
  const {
    email,
    first_name,
    last_name,
    is_registered,
    partner,
    policy_state,
    policy_zipcode,
    quote_pets = [],
    id,
    tracking_id,
    transaction_fee,
    status,
  } = quote;

  return {
    email,
    firstName: first_name,
    id,
    isRegistered: is_registered,
    lastName: last_name,
    partner,
    pets: quote_pets.map(transformPet),
    policyZipcode: policy_zipcode,
    policyState: policy_state,
    status,
    trackingId: tracking_id,
    transactionFee: transaction_fee,
    vet: quote.vet || {},
  };
}
