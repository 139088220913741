import { useQuery } from 'react-query';

import { fetchWrapper } from '@pumpkincare/portal-shared';

export default function useVetProducts(stateCode) {
  return useQuery(
    ['vetProducts', stateCode],

    async () =>
      fetchWrapper(`https://utils.api.pumpkin.care/v1/states/${stateCode}`, {
        isExternal: true,
      }),

    {
      enabled: !!stateCode,
      placeholderData: {},
      staleTime: 1000 * 60 * 60, // 1 hour in milliseconds
    }
  );
}
