import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';

import { matchScreenSizes } from './media-utils';

const BREAKPOINTS_PROVIDER_ERROR = new Error(
  'The hook must be used within a BreakpointProvider'
);

const Breakpoints = createContext();

function BreakpointsProvider({ children }) {
  const [mediaMatches, setMediaMatches] = useState(matchScreenSizes());

  // debounced resize listener only changes state if resize past breakpoints defined in matchScreenSizes
  const listener = useDebouncedCallback(() => {
    const newMatches = matchScreenSizes();
    const hasStateChanged = Object.keys(newMatches).some(
      key => newMatches[key] !== mediaMatches[key]
    );

    if (hasStateChanged) setMediaMatches(newMatches);
  }, 100);

  useEffect(() => {
    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, [listener]);

  return (
    <Breakpoints.Provider value={mediaMatches}>{children}</Breakpoints.Provider>
  );
}

BreakpointsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BreakpointsProvider;

export function useBreakpoints() {
  const context = useContext(Breakpoints);

  if (context === undefined) {
    throw BREAKPOINTS_PROVIDER_ERROR;
  }

  return context;
}
