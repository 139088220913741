import { UP_TO_5_MONTHS, UP_TO_11_MONTHS } from '@pumpkincare/portal-shared';

function addOption(item, key) {
  return {
    ...item,
    option: {
      key,
      value:
        item.id === 'LimitUnlimited'
          ? item.id.replace(/Limit/, '')
          : item.id.replace(/\D/g, ''),
    },
  };
}

export function transformPolicyOptions({ prices, copays, deductibles, limits }) {
  /*
    if lever has more than one option, it'll be selectable and need option object for rendering
    otherwise, don't map and add option
   */
  return {
    prices,
    copays:
      copays.length > 1 ? copays.map(copay => addOption(copay, 'copay')) : copays,
    deductibles:
      deductibles.length > 1
        ? deductibles.map(deductible => addOption(deductible, 'deductible'))
        : deductibles,
    limits:
      limits.length > 1
        ? limits.map(limit => addOption(limit, 'annual_limit'))
        : limits,
  };
}

export function transformPetAgeToMonths(petAge) {
  return petAge === UP_TO_5_MONTHS
    ? 5
    : petAge === UP_TO_11_MONTHS
    ? 11
    : parseInt(petAge) * 12;
}
