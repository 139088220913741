import { useQuery } from 'react-query';

import { fetchWrapper } from '@pumpkincare/portal-shared';

export default function usePlans(vetId) {
  return useQuery(
    ['vetPlans', vetId],

    async () => fetchWrapper(`/utils/vets/${vetId}/plans`),

    {
      enabled: !!vetId,
      placeholderData: [],
      staleTime: 1000 * 60 * 60, // 1 hour in milliseconds
    }
  );
}
