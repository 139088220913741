import { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '../../typography.module.css';
import styles from './radio.module.css';

function Radio({
  classes = {},
  style = {},
  label = '',
  onChange,
  small,
  variant,
  'data-testid': dataTestId,
  ...restInputProps
}) {
  const radioClassName = classNames(styles.radio, {
    [styles.smallRadio]: small,
  });
  const labelClassName = classNames(
    Typography.legalBody,
    styles.label,
    classes.label,
    {
      [styles.medium]: !small,
      [styles.small]: small,
    }
  );

  const variants = useMemo(
    () => ({
      isDefault: variant === 'default',
      isLight: variant === 'light',
    }),
    [variant]
  );

  const imageClassName = classNames(styles.image, {
    [styles.imageLight]: variants.isLight,
  });

  return (
    <label
      className={classNames(styles.root, classes.root)}
      style={style}
      onChange={onChange}
      data-testid={dataTestId}
    >
      <input
        className={radioClassName}
        type='radio'
        onChange={() => {}}
        {...restInputProps}
      />
      <div className={imageClassName} aria-hidden />
      <span className={labelClassName}>{label}</span>
    </label>
  );
}

Radio.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
  }),

  name: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func,
  /** a cute small radio button */
  small: PropTypes.bool,
  style: PropTypes.object,
  checked: PropTypes.bool,
  'data-testid': PropTypes.string,
  /** ADA related - you should wrap Radio in a RadioGroup and let it handle this! */
  'aria-checked': PropTypes.bool,
  /** ADA related - you should wrap Radio in a RadioGroup and let it handle this! */
  tabIndex: PropTypes.number,
};

export default Radio;
