export function validateEmail(email) {
  return !!email.match(
    '^([a-zA-Z0-9\\+_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$'
  );
}

/* valid zips: 12345 and 12345-1234 */
export function validateZip(zip) {
  return !!zip.match('^[0-9]{5}(?:-[0-9]{4})?$');
}

export function checkPasswordRequirements(password) {
  const atLeast12Char = /^(?=.{12,}).*$/;
  const atLeastOneLowercase = /^(?=.*[a-z]).*$/;
  const atLeastOneUppercase = /^(?=.*[A-Z]).*$/;
  const atLeastOneDigit = /^(?=.*[0-9]).*$/;
  // eslint-disable-next-line no-useless-escape
  const atLeastOneSpecialChar = /^(?=.*[\^$*.\[\]{}()?\-"!@#%&/\\,><':;|_~`]).*$/;

  const hasAtLeast12Char = atLeast12Char.test(password);
  const hasAtLeastOneLowercase = atLeastOneLowercase.test(password);
  const hasAtLeastOneUppercase = atLeastOneUppercase.test(password);
  const hasAtLeastOneDigit = atLeastOneDigit.test(password);
  const hasAtLeastOneSpecialChar = atLeastOneSpecialChar.test(password);

  const valid = {
    hasAtLeast12Char,
    hasAtLeastOneLowercase,
    hasAtLeastOneUppercase,
    hasAtLeastOneDigit,
    hasAtLeastOneSpecialChar,
    hasErrors:
      !hasAtLeast12Char ||
      !hasAtLeastOneLowercase ||
      !hasAtLeastOneUppercase ||
      !hasAtLeastOneDigit ||
      !hasAtLeastOneSpecialChar,
  };

  return valid;
}

export function validateDateMMDDYYY(dateStr) {
  return /^([1-9]|((0)[1-9])|((1)[0-2]))(\/)([1-9]|((0)[1-9])|([1-2][0-9])|((3)[0-1]))(\/)\d{4}$/i.test(
    dateStr
  );
}

export function validateOnlyNumbers(str, canBeEmpty = false) {
  return (canBeEmpty && str === '') || /^([\d.]+)$/i.test(str);
}
