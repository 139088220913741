import { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { assessIsGuidedAndFirstVisit, setWasGuided } from './tooltip-utils';

import ButtonStyles from '../../button.module.css';
import Typography from '../../typography.module.css';
import styles from './tooltip.css';

import iconTooltip from './icon_tooltip.svg';
import close from './simple-x-grey.svg';

const noop = () => {};

function Tooltip({
  children,
  content,
  classes,
  guideKey,
  hasArrow,
  leftAlignedText,
  leftAligned,
}) {
  const isGuidedAndNew = assessIsGuidedAndFirstVisit(guideKey);
  const [isVisible, setVisibility] = useState(isGuidedAndNew);
  const tooltipRef = useRef();
  const placeholderRef = useRef();

  const [offPageClass, setOffPageClass] = useState(null);

  const { handleMouseEnter, handleMouseLeave, handleFocus, handleBlur } = useMemo(
    () => ({
      handleMouseEnter: !isGuidedAndNew ? () => setVisibility(true) : noop,
      handleMouseLeave: !isGuidedAndNew ? () => setVisibility(false) : noop,
      handleFocus: !isGuidedAndNew ? () => setVisibility(true) : noop,
      handleBlur: !isGuidedAndNew ? () => setVisibility(false) : noop,
    }),
    [isGuidedAndNew]
  );

  useEffect(() => {
    if (isGuidedAndNew) {
      const dimensions = tooltipRef.current.getBoundingClientRect();
      const { bottom, height, top } = dimensions;

      if (
        bottom + height > window.innerHeight ||
        top + height < window.innerHeight
      ) {
        window.scrollTo(0, tooltipRef.current.offsetTop + height);
      }

      setWasGuided(guideKey);
    }
  }, [guideKey, isGuidedAndNew, tooltipRef]);

  useEffect(() => {
    if (isVisible) {
      const placeholderRect = placeholderRef.current.getBoundingClientRect();
      const dropdownRect = tooltipRef.current.getBoundingClientRect();

      const dropdownRightX = dropdownRect.x + dropdownRect.width;
      const placeholderRightX = placeholderRect.x + placeholderRect.width;

      if (dropdownRect.x < 0) {
        setOffPageClass({
          left: '0',
          right: 'auto',
          transform: `translateX(${-placeholderRect.x + 32}px)`,
          margin: 'auto',
        });
      } else if (dropdownRightX > window.outerWidth) {
        setOffPageClass({
          left: 'auto',
          right: '0',
          transform: `translateX(${window.outerWidth - placeholderRightX - 32}px)`,
          margin: 'auto',
        });
      } else {
        setOffPageClass(null);
      }
    }
  }, [placeholderRef, tooltipRef, isVisible]);

  function handleKeyDown(e) {
    if (e.key === 'Escape' && isVisible && !isGuidedAndNew) {
      setVisibility(false);
    }
  }

  return (
    <div
      className={classNames(styles.container, classes.container, {
        [styles.textLeft]: leftAlignedText,
      })}
    >
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        tabIndex='0'
        className={classNames(styles.activationDiv, classes.activationDiv)}
        role='tooltip'
        ref={placeholderRef}
      >
        {content ? content : <img src={iconTooltip} />}
      </div>
      {isVisible ? (
        <div
          ref={tooltipRef}
          style={offPageClass}
          className={classNames(
            hasArrow ? styles.arrowTooltip : styles.basicTooltip,
            classes.tooltip,
            offPageClass
              ? null
              : hasArrow
              ? styles.center
              : leftAligned
              ? styles.left
              : styles.right,
            {
              [styles.wide]: guideKey,
            }
          )}
        >
          {isGuidedAndNew ? (
            <div className={classNames(Typography.h6, styles.newFeature)}>
              New Feature!
            </div>
          ) : null}

          {children}

          {isGuidedAndNew ? (
            <button
              onClick={() => setVisibility(false)}
              className={classNames(styles.close, ButtonStyles.unset)}
            >
              <img src={close} alt='' />
            </button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

Tooltip.defaultProps = {
  classes: {},
  leftAligned: false,
  hasArrow: false,
};

Tooltip.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    tooltip: PropTypes.string,
    activationDiv: PropTypes.string,
  }),

  content: PropTypes.node,
  leftAlignedText: PropTypes.bool,
  guideKey: PropTypes.string,
  hasArrow: PropTypes.bool,
  leftAligned: PropTypes.bool,
};

export default Tooltip;
