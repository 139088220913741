import { useMutation } from 'react-query';

import fetchWrapper from '../fetch-wrapper';

/*
  based on the context of the request, get a signed url to upload to s3, and all other state
  associated with a react-query mutation
 */
export function useMutatePostSignedUrl({ hookOptions = {} } = {}) {
  return useMutation(
    ({ body, ...fetchOptions }) =>
      fetchWrapper(`/documents/signed-url`, {
        method: 'POST',
        body,
        ...fetchOptions,
      }),
    hookOptions
  );
}

export function useMutateUploadToS3({ hookOptions = {} } = {}) {
  return useMutation(({ file, data, ...fetchOptions }) => {
    const formData = new FormData();
    const { fields, url } = data.signed_url;

    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key]);
    });

    file.key = fields.key;
    // Actual file has to be appended last.
    formData.append('file', file);

    return fetchWrapper(url, {
      method: 'POST',
      isExternal: true,
      formData,
      ...fetchOptions,
    }).then(response => ({ response, signedUrls: data.signed_url }));
  }, hookOptions);
}

/*
  based on the context of the request, post an uploaded document to our DB, and all other state
  associated with a react-query mutation
 */
export function useMutatePostDocument({ hookOptions = {} } = {}) {
  return useMutation(
    ({ body, ...fetchOptions }) =>
      fetchWrapper(`/documents`, {
        method: 'POST',
        body,
        ...fetchOptions,
      }),
    hookOptions
  );
}
