import { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  assessIsGuidedAndFirstVisit,
  setWasGuided,
} from '@pumpkincare/portal-shared';

import Button from '../button';

import styles from './tooltip.css';

import close from './simple-x-grey.svg';

const noop = () => {};

function Tooltip({ children, content, classes, guideKey, leftAligned }) {
  const isGuidedAndNew = assessIsGuidedAndFirstVisit(guideKey);
  const [isVisible, setVisibility] = useState(isGuidedAndNew);
  const tooltipRef = useRef();

  const { handleMouseEnter, handleMouseLeave } = useMemo(
    () => ({
      handleMouseEnter: !isGuidedAndNew ? () => setVisibility(true) : noop,
      handleMouseLeave: !isGuidedAndNew ? () => setVisibility(false) : noop,
    }),
    [isGuidedAndNew]
  );

  useEffect(() => {
    if (isGuidedAndNew) {
      const dimensions = tooltipRef.current.getBoundingClientRect();
      const { bottom, height, top } = dimensions;

      if (
        bottom + height > window.innerHeight ||
        top + height < window.innerHeight
      ) {
        window.scrollTo(0, tooltipRef.current.offsetTop + height);
      }

      setWasGuided(guideKey);
    }
  }, [guideKey, isGuidedAndNew]);

  return (
    <div
      className={classNames(styles.container, classes.container, {
        [styles.left]: leftAligned,
      })}
    >
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ position: 'relative' }}
      >
        {children}
      </div>

      {isVisible ? (
        <div
          ref={tooltipRef}
          className={classNames(styles.tooltip, classes.tooltip, {
            [styles.wide]: guideKey,
          })}
        >
          {isGuidedAndNew ? (
            <h6 className={styles.newFeature}>New Feature!</h6>
          ) : null}

          {content}

          {isGuidedAndNew ? (
            <Button onClick={() => setVisibility(false)} className={styles.close}>
              <img src={close} alt='' />
            </Button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

Tooltip.defaultProps = {
  classes: {},
};

Tooltip.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    tooltip: PropTypes.string,
  }),

  content: PropTypes.node,
  leftAligned: PropTypes.bool,
  guideKey: PropTypes.string,
};

export default Tooltip;
