import PropTypes from 'prop-types';

import SvgIcon, { SvgIconPropTypes } from './svg-icon';

function Hamburger({ stroke, ...rest }) {
  const { width, height } = rest;

  return (
    <SvgIcon fill='none' viewBox={`0 0 ${width} ${height}`} {...rest}>
      <g clipPath='url(#clip0_1489_61098)'>
        <line
          x1='1'
          y1='1'
          x2='15'
          y2='1'
          stroke={stroke}
          strokeWidth='2'
          strokeLinecap='square'
        />
        <line
          x1='1'
          y1='7'
          x2='10.4286'
          y2='7'
          stroke={stroke}
          strokeWidth='2'
          strokeLinecap='square'
        />
        <line
          x1='1'
          y1='13'
          x2='15'
          y2='13'
          stroke={stroke}
          strokeWidth='2'
          strokeLinecap='square'
        />
      </g>
      <defs>
        <clipPath id='clip0_1489_61098'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

Hamburger.defaultProps = {
  width: 16,
  height: 14,
  stroke: '#EEF0FE',
};

Hamburger.propTypes = {
  ...SvgIconPropTypes,
  stroke: PropTypes.string,
};

export default Hamburger;
