import { useQuery } from 'react-query';

import fetchWrapper from '../fetch-wrapper';
import { validateZip } from '../utils';

export function useZipcodes(zip, options = {}) {
  return useQuery(
    ['zipcode', zip],

    () =>
      fetchWrapper(`https://utils.api.pumpkin.care/v1/zipcodes/${zip.slice(0, 5)}`, {
        isExternal: true,
      }),

    {
      enabled: validateZip(zip),
      retry: false,
      placeholderData: {},
      staleTime: 1000 * 60 * 10,
      ...options,
    }
  );
}
