import Store from 'store';

import { QUOTE_FLOW } from './model/categories';
import {
  EMAIL,
  NUMBER_OF_PETS,
  PET_TYPE,
  USER_ID,
  USER_TYPE,
} from './model/dimensions';

const analyticsQueue = {
  isReady: false,
  trackEvents: [],
  identifyEvents: [],
  pageEvents: [],
};

export function getTrackedCustomProps() {
  return (
    Store.get('trackedCustomProps') || {
      [USER_TYPE]: 'Visitor',
      [PET_TYPE]: 'None',
      [NUMBER_OF_PETS]: 0,
      [USER_ID]: 'None',
      [EMAIL]: 'None',
    }
  );
}

export function setTrackedCustomProps(payload) {
  const {
    userId,
    userPets = [],
    email,
  } = {
    ...getTrackedCustomProps(),
    ...payload,
  };

  Store.set('trackedCustomProps', {
    [USER_TYPE]: userId ? 'Policyholder' : 'Visitor',
    [PET_TYPE]: userPets.length ? userPets[0].species : 'None',
    [NUMBER_OF_PETS]: userPets.length.toString(),
    [USER_ID]: userId || 'None',
    [EMAIL]: email || 'None',
  });
}

export function analyticsTrack(payload, customProperties) {
  if (analyticsQueue.isReady) {
    const { event, ...rest } = payload;
    const properties = {
      ...customProperties,
      ...rest,
    };

    window.analytics.track(event, properties);
  } else {
    analyticsQueue.trackEvents.push({ payload, customProperties });
  }
}

export function getAnonymousId() {
  return window.analytics?.user?.().anonymousId();
}

export function setAnonymousId(id) {
  window.analytics.setAnonymousId?.(id);
}

export function analyticsIdentify(traits, userId) {
  if (analyticsQueue.isReady) {
    window.analytics.identify(traits, userId);
  } else {
    analyticsQueue.identifyEvents.push({ traits, userId });
  }
}

export function analyticsPage(traits) {
  if (analyticsQueue.isReady) {
    window.analytics.page(getAnonymousId(), traits);
  } else {
    analyticsQueue.pageEvents.push(traits);
  }
}

export function runQueuedAnalytics() {
  analyticsQueue.isReady = true;

  analyticsQueue.trackEvents.forEach(({ payload, customProperties }) => {
    analyticsTrack(payload, customProperties);
  });

  analyticsQueue.identifyEvents.forEach(({ traits, userId }) => {
    analyticsIdentify(traits, userId || getAnonymousId());
  });

  analyticsQueue.completeTracks = analyticsQueue.trackEvents;
  analyticsQueue.trackEvents = [];

  analyticsQueue.completeIdentifies = analyticsQueue.identifyEvents;
  analyticsQueue.identifyEvents = [];

  analyticsQueue.completePages = analyticsQueue.pageEvents;
  analyticsQueue.pageEvents = [];
}

// ...QuoteFlowEvents,
export function GAQuoteFlowSubmitPetBreed(petBreed) {
  analyticsTrack(
    {
      category: QUOTE_FLOW,
      event: 'Submit Pet Breed',
      label: petBreed,
    },
    getTrackedCustomProps()
  );
}

export function GAQuoteFlowSubmitPetCount({ totalCount }) {
  analyticsTrack(
    {
      category: QUOTE_FLOW,
      event: 'Submit Pets',
      label: 'Total Pet Count',
      value: totalCount,
    },
    getTrackedCustomProps()
  );
}

export function GAQuoteFlowSubmitNameAndEmail() {
  analyticsTrack(
    {
      category: QUOTE_FLOW,
      event: 'Submit Name & Email',
    },
    getTrackedCustomProps()
  );
}

export function GAQuoteFlowClickToEditPetInfo(location) {
  analyticsTrack(
    {
      category: QUOTE_FLOW,
      event: 'Click to edit pet info',
      label: location,
    },
    getTrackedCustomProps()
  );
}

export function GAQuoteFlowClickHowWillWeUseThis() {
  analyticsTrack(
    {
      category: QUOTE_FLOW,
      event: 'Click How will we use this?',
    },
    getTrackedCustomProps()
  );
}

export function trackPhotoCardGalleryModal(visitId) {
  analyticsTrack({
    event: 'Open Photo Card Modal',
    category: 'Photo Card Gallery',
    label: `Visit: ${visitId}`,
  });
}

export function trackPhotoCardGalleryDownload(visitId, type) {
  analyticsTrack({
    event: 'Download Photo Card Image',
    category: 'Photo Card Gallery',
    label: `Visit: ${visitId}, Type: ${type}`,
  });
}

export function trackViewPreview(category, label) {
  analyticsTrack({
    event: 'View Content Preview',
    category,
    label,
  });
}

export function trackMultiEmailModal(category, label) {
  analyticsTrack({
    event: 'Email to Clients',
    category,
    label,
  });
}

export function trackMultiEmailSend(category, label) {
  analyticsTrack({
    event: 'Send',
    category,
    label,
  });
}

export function trackMultiEmailSuccess(category, label) {
  analyticsTrack({
    event: 'Email Content Succes',
    category,
    label,
  });
}

export function trackSocialDropdown(category, label) {
  analyticsTrack({
    event: 'Share to Social Dropdown',
    category,
    label,
  });
}

export function trackShareToSocial(category, label, channel) {
  analyticsTrack({
    event: 'Share to Social Channel',
    category,
    label: `Label: ${label}, Channel: ${channel}`,
  });
}

export function trackCustomerPEP(action, version, area) {
  const prefix = area ? `${area}, ` : '';
  analyticsTrack({
    event: `${action} | PEP`,
    category: 'PEP',
    label: `${prefix}PEP${version}`,
  });
}
