import { useQuery } from 'react-query';

import { AUTH_VET_LS_KEY, getIsLoggedIn } from '@pumpkincare/portal-shared';

import { getVetUser, getVetUserSelf } from './user-service';

export const VET_USER_SELF_QUERY = 'vetUserSelf';
export const VET_USER_QUERY = 'vetUser';

export const getVetSelfVetId = state => state.vet_practice_id;
export const getVetSelfId = state => state.id;

export function getVetUserSelfQueryFn() {
  return getVetUserSelf();
}

export function getVetUserQueryFn(id) {
  return getVetUser(id);
}

export function useVetSelf({ select, enabled = true } = {}) {
  return useQuery(
    [VET_USER_SELF_QUERY],

    getVetUserSelfQueryFn,

    {
      cacheTime: Infinity,
      enabled: enabled && getIsLoggedIn(AUTH_VET_LS_KEY),
      placeholderData: {
        id: '',
        vet_practice_id: '',
        email: '',
      },
      select,
    }
  );
}

export function useVetUser(id, select) {
  return useQuery(
    [VET_USER_QUERY, id],

    async () => getVetUserQueryFn(id),

    {
      cacheTime: Infinity,
      enabled: !!id && getIsLoggedIn(AUTH_VET_LS_KEY),
      placeholderData: {
        id: '',
        vet_practice_id: '',
        email: '',
        first_name: '',
        last_name: '',
      },
      select,
    }
  );
}
