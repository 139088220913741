import { useQuery } from 'react-query';

import { POLICY_BASE_API } from '@pumpkincare/portal-config';
import { fetchWrapper, validateZip } from '@pumpkincare/portal-shared';

const VETS_SEARCH_QUERY = 'vets-search';

export function useVetsSearch({ search, zipcode }, options = {}) {
  const body = { vet_name: search };
  if (validateZip(zipcode)) body.zipcode = zipcode;

  return useQuery(
    [VETS_SEARCH_QUERY, search, zipcode],

    () =>
      fetchWrapper('/vet-practices/zipcode-suggestions', {
        method: 'POST',
        baseApi: POLICY_BASE_API,
        body,
      }),

    {
      enabled: !!search,
      retry: false,
      placeholderData: [],
      staleTime: 1000 * 60 * 10,
      ...options,
    }
  );
}
