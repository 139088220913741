import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(isBetween);

// internal only. we do not want to expose dayjs
function dayjsFromISO(datetimeStr, inputTimezone = 'utc') {
  const userTimezone = dayjs.tz.guess();

  if (inputTimezone === 'utc') {
    // let's use the full ISO string
    return dayjs(datetimeStr);
  }

  if (inputTimezone === 'local') {
    // ignore time from datetime
    // e.g., pet birthdate -> 2020-01-15 00:00:00 should be interpreted as local date,
    // even if it's stored as datetime utc in the database for some reason.
    return dayjs.tz(datetimeStr.split('T')[0], userTimezone);
  }

  // assumes datetime from an specific timezone
  // e.g., policy_start_date. Although stored as YYYY-MM-DD 00:00:00, the value
  // represents an America/New_York datetime.
  return dayjs.tz(datetimeStr, inputTimezone);
}

// internal only. we do not want to expose dayjs
function dayjsToString(dayjsObj, format = 'MM/DD/YYYY') {
  const userTimezone = dayjs.tz.guess();
  return dayjsObj.tz(userTimezone).format(format);
}

// GENERIC FUNCTIONS
export function currentISODate(offsetTimezone = 'utc') {
  if (offsetTimezone === 'utc') {
    // no offset = +00:00
    return dayjs().utc().format();
  }

  if (offsetTimezone === 'local') {
    // local offset
    // -04:00 if America/New_York
    // -07:00 if America/Los_Angeles
    const userTimezone = dayjs.tz.guess();
    return dayjs().tz(userTimezone).format();
  }

  // use specific offset
  return dayjs().tz(offsetTimezone).format();
}

export function formatISODate(
  datetimeStr,
  { inputTimezone = 'utc', format = 'MM/DD/YYYY' } = {}
) {
  const date = dayjsFromISO(datetimeStr, inputTimezone);
  return dayjsToString(date, format);
}

export function calculateAndFormatISODate(
  datetimeStr,
  operations,
  { inputTimezone = 'utc', format = 'MM/DD/YYYY' } = {}
) {
  /* eslint-disable no-useless-escape */
  const re = /([+\-]?\d+)(?:\s*)([a-z]+)/gi;
  const ops = [...operations.matchAll(re)].map(op => {
    return { amount: op[1], unit: op[2] };
  });

  let date = dayjsFromISO(datetimeStr, inputTimezone);

  ops.forEach(op => {
    date = date.add(op.amount, op.unit);
  });

  return dayjsToString(date, format);
}

export function diffSince(
  datetimeStr,
  unit = 'day',
  { inputTimezone = 'utc' } = {}
) {
  const date = dayjsFromISO(datetimeStr, inputTimezone);
  let now;

  if (inputTimezone === 'utc') {
    now = dayjs().utc();
  } else if (inputTimezone === 'local') {
    const userTimezone = dayjs.tz.guess();
    now = dayjs().tz(userTimezone);
  } else {
    now = dayjs().tz(inputTimezone);
  }

  return now.diff(date, unit);
}

export function dateIsBefore(datetimeStr, beforeDatetimeStr) {
  return dayjs(datetimeStr).isBefore(dayjs(beforeDatetimeStr));
}

export function dateIsAfter(datetimeStr, afterDatetimeStr) {
  return dayjs(datetimeStr).isAfter(dayjs(afterDatetimeStr));
}

export function dateIsBetween(
  datetimeStr,
  startDatetimeStr,
  endDatetimeStr,
  inclusivity = '[)'
) {
  return dayjs(datetimeStr).isBetween(
    dayjs(startDatetimeStr),
    dayjs(endDatetimeStr),
    null,
    inclusivity
  );
}

export function formatMMDDYYYYtoYYYYMMDD(dateStr) {
  return formatISODate(dateStr, {
    inputTimezone: 'local',
    format: 'YYYY-MM-DD',
  });
}
